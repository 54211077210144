import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
// hooks
import { useSettingsContext } from '../settings';
import { PUBLIC_URL } from 'src/config-global';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const settings = useSettingsContext();

  const isNavMini = settings.themeLayout === 'mini';

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------

  const jiaccLogo = (
    <>
      {!isNavMini ? (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: '145px',
            height: 'auto',
            ...sx,
          }}
          {...other}
        >
          <img
            src={`${PUBLIC_URL}/logo/jiacc-logo-full.png`}
            alt="JIACC Logo"
            style={{
              width: 'auto',
              height: '100%',
            }}
          />
        </Box>
      ) : (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: '48px',
            height: 'auto',
            ...sx,
          }}
          {...other}
        >
          <img
            src={`${PUBLIC_URL}/logo/jiacc-logo.png`}
            alt="JIACC Logo"
            style={{
              width: 'auto',
              height: '100%',
            }}
          />
        </Box>
      )}
    </>
  );

  if (disabledLink) {
    return jiaccLogo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {jiaccLogo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
