import { Box, Button, Card, Checkbox, Typography } from "@mui/material";
import { useGlobalDialogContext } from "src/components/global-dialog";
import { useLocales } from "src/locales";
import { useRouter } from "src/routes/hooks";
import { useGlobalPromptContext } from "src/components/global-prompt";
import { useCallback, useEffect, useMemo, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import { useGetRequest, usePostRequest } from "@ahmadmubarak98/use-fetch";
import { endpoints } from "src/utils/endpoints";
import { axiosFetcher } from "src/utils/axios";
import { LoadingScreen } from "src/components/loading-screen";
import { toast } from "react-hot-toast";


export default function RolePermissions({ roleId }) {
    const router = useRouter();
    const globalDialog = useGlobalDialogContext();
    const globalPrompt = useGlobalPromptContext();
    const { t, currentLang } = useLocales();
    // State
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const getRolePermissionsReq = useGetRequest({
        url: endpoints.internal.getPermissionsByRoleId(roleId),
        options: {
            initialLoading: true,
            initialData: [],
            fetcher: axiosFetcher,
        },

    });

    const getAllScreensReq = useGetRequest({
        url: endpoints.internal.getAllScreens,
        options: {
            initialLoading: true,
            initialData: [],
            fetcher: axiosFetcher,
        }
    });

    const saveRolePermissionsReq = usePostRequest({
        url: endpoints.internal.editRolePermissions,
        options: {
            method: 'PATCH',
            initialLoading: false,
            fetchOnMount: false,
            fetcher: axiosFetcher,
        }
    });

    const allPermissions = useMemo(() => {
        if (getAllScreensReq.data) {
            return getAllScreensReq.data.map(screen => ({
                label: screen[currentLang.value === "ar" ? "nameAr" : "nameEn"],
                value: screen.code,
                children: screen.actions.map((action) => ({
                    label: action[currentLang.value === "ar" ? "nameAr" : "nameEn"],
                    value: `${screen.code}.${action.code}`,
                }))
            }));
        }

        return []
    }, [getAllScreensReq.data]);

    const onSaveRolePermissions = useCallback(() => {
        saveRolePermissionsReq.trigger({
            payload: {
                roleId,
                permissions: checked
            },
            onSuccess: () => {
                globalDialog.onClose();
                toast(t('saved_success'), {
                    icon: '✅',
                });
            }
        })
    }, [checked, roleId]);

    useEffect(() => {
        if (getRolePermissionsReq.data) {
            setChecked(getRolePermissionsReq.data)
            setExpanded(getAllScreensReq.data
                .filter(screen => screen.actions.map(screenAction => screenAction.code)
                    .some(screenActionCode => {
                        return getRolePermissionsReq.data.includes(`${screen.code}.${screenActionCode}`)
                    })
                ).map(screen => screen.code))
        }
    }, [getRolePermissionsReq.data]);



    if (getAllScreensReq.isLoading || getRolePermissionsReq.isLoading) return <LoadingScreen />;

    return (
        <Box py={2}>
            <Card>

                <Box sx={{
                    my: 2,
                    py: 2,
                    background: '#f9f9f9',
                    maxHeight: 400,
                    overflowY: 'auto'
                }}
                >
                    <CheckboxTree
                        nodes={allPermissions}
                        checked={checked}
                        expanded={expanded}
                        onCheck={(checked) => setChecked(checked)}
                        onExpand={(expanded) => setExpanded(expanded)}
                        nativeCheckboxes={true}
                        icons={{
                            check: <Checkbox checked />,
                            halfCheck: <Checkbox checked />,
                            uncheck: <Checkbox checked={false} />,
                            expandClose: <Typography color="black">⬅</Typography>,
                            expandOpen: <Typography color="black">⬇</Typography>,
                            parentOpen: '',
                            parentClose: '',
                            leaf: <Typography fontSize={12}>🔑</Typography>,
                            expandAll: <Typography>{t('expand_all')}</Typography>,
                            collapseAll: <Typography>{t('collapse_all')}</Typography>,
                        }}
                        showExpandAll={true}
                    />
                </Box>
                <Button variant="contained" color="primary" onClick={onSaveRolePermissions}>
                    {t("save")}
                </Button>
            </Card>
        </Box>
    )
}