import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, CircularProgress, IconButton, TextField } from '@mui/material';

//
import Iconify from '../iconify';
import SvgColor from '../svg-color/svg-color';
//
import RejectionFiles from './errors-rejection-files';
import { useState } from 'react';

// ----------------------------------------------------------------------

export default function UploadField({ error, file, disabled, helperText, sx, loading, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled: disabled || loading,
    // accept: {
    //   'image/jpg': [],
    //   'image/jpeg': [],
    //   'image/png': [],
    //   'image/gif': [],
    //   'application/pdf': [],
    //   'application/msword': [],
    // },
    ...other,
  });
  const [fileName, setFileName] = useState('')

  const hasFile = !!file;

  const hasError = isDragReject || !!error;

  const fileUrl = ['string', 'number'].includes(typeof file) ? file : file?.path;

  const renderPlaceholder = (
    <>
      {file?.path}
      <TextField
        sx={{
          color: 'text.disabled',
          '&:hover': {
            opacity: 0.72,
          },
          ...(hasError && {
            color: 'error.main',
          }),
        }}
        value={fileUrl || fileName}
        disabled
        InputProps={{
          startAdornment: (
            <>
              {loading && <CircularProgress size={24} />}
            </>
          ),
          endAdornment: (
            <IconButton {...getRootProps()}>
              <SvgColor src="/assets/icons/designer/attach.svg" color="primary.main" />
            </IconButton>
          ),
        }}
        fullWidth
      />
    </>
  );

  const renderContent = <>{renderPlaceholder}</>;

  return (
    <>
      <Box
        sx={{
          cursor: 'pointer',
          ...(hasFile && {
            ...(hasError && {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            }),
          }),
          ...sx,
        }}
      >
        <input
          {...getInputProps()}
          onChange={e => {
            getInputProps()?.onChange(e)
            setFileName(e?.target?.files?.[0]?.name)
            e.target.value = null
          }}
        />

        {renderContent}
      </Box>

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} />
    </>
  );
}

UploadField.propTypes = {
  disabled: PropTypes.object,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.string,
  sx: PropTypes.object,
};
