import { Helmet } from 'react-helmet-async';
import { useScreenGuard } from 'src/hooks/use-permissions';
import { useLocales } from 'src/locales';
import RolesListView from 'src/sections/system-settings/view/roles/roles-list-view';
// sections

// ----------------------------------------------------------------------

export default function RolesListPage() {
  const { t } = useLocales();

  useScreenGuard('SYSTEM_SETTINGS', 'MANAGE_ROLES')

  return (
    <>
      <Helmet>
        <title>{t('screen_role_list', {
          systemName: t('system_name')
        })}</title>
      </Helmet>

      <RolesListView />
    </>
  );
}
