import styled from "@emotion/styled";
import { Card, CardHeader, Container, Stack, Typography } from "@mui/material";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { useRouter } from "src/routes/hooks";
import { paths } from "src/routes/paths";

const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    flex: 1,
    flexShrink: 0,
    minWidth: '250px',
    maxWidth: '250px',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: 2,
    transition: 'all 0.2s',
    border: '1px solid transparent',
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
        borderColor: theme.palette.primary.main,
    }
}));

export default function AllSettingsView() {
    const settings = useSettingsContext();
    const router = useRouter();
    const { t } = useLocales();

    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <CardHeader title={t('system_settings')} />

            <Stack direction="row" gap={4} flexWrap="wrap">
                <StyledCard onClick={() => router.push(paths.dashboard.system_settings.stakeholders_list)}>
                    <Typography component="div" textAlign="start" variant="h6">
                        {t('stakeholders_fields')}
                    </Typography>
                </StyledCard>
                <StyledCard onClick={() => router.push(paths.dashboard.system_settings.stakeholders_list + `?entityType=establishment`)}>
                    <Typography component="div" textAlign="start" variant="h6">
                        {t('stakeholders_fields_est')}
                    </Typography>
                </StyledCard>
                <StyledCard onClick={() => router.push(paths.dashboard.system_settings.screens_list)}>
                    <Typography component="div" textAlign="start" variant="h6">
                        {t('screen_management')}
                    </Typography>
                </StyledCard>
                <StyledCard onClick={() => router.push(paths.dashboard.system_settings.roles_list)}>
                    <Typography component="div" textAlign="start" variant="h6">
                        {t('roles_permissions_management')}
                    </Typography>
                </StyledCard>
            </Stack>
        </Container>
    )
}