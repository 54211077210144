import _ from "lodash";
import alovaInstance, { alovaUploadInstance } from "./alovaInstance";
import { HOST_API } from "src/config-global";

// Get Select and Radio-Gruop options from API
export function optionsFromAPISourceGetter(srcUrl, token, params, labelKey, valueKey, strategy = "appendQS") {
  let url = srcUrl;
  if (strategy === "appendUrl") {
    Object.keys(params).forEach(key => {
      url += `/${params[key]}`
    })
  }

  return alovaInstance.Get(url, {
    ...(strategy === "appendQS" && {
      params,
      headers: {
        token: token
      }
    }),
    localCache: {
      // Set cache mode to memory mode
      mode: "memory",

      // unit is milliseconds
      // When set to `Infinity`, it means that the data will never expire, and when it is set to 0 or a negative number, it means no caching
      // 1000 is 1 second
      // 1 * 1000 * 60 is 1 minute
      expire: 1 * 1000 * 60 * 5,
    },
    async transformData(rawData, headers) {
      let data = rawData.data || rawData;
      let newOptions = data?.map((item) => ({
        label: _.get(item, labelKey),
        value: _.get(item, valueKey),
      }));

      return newOptions;
    },
  });
}

export function getCSPDData(data = {}) {
  const { nat_id, params } = data;
  // was /backend
  return alovaInstance.Get(`${HOST_API}/api/cspd/${nat_id}`,
    {
      params,
    }
  )
}

export function getMainEntities() {
  // was /backend
  return alovaInstance.Get(`${HOST_API}/api/lookups/mainEntities`, {
    async transformData(rawData, headers) {
      return rawData.data || rawData;
    }
  })
}

export function fetchRequest(endpoint, data, method = "GET", cache = "none") {
  if (method.toUpperCase() === "POST") {
    return alovaInstance.Post(endpoint, data,
      {
        headers: {
          ...(localStorage.getItem('i18nextLng') ? ({
            'Accept-Language': localStorage.getItem('i18nextLng')
          }) : ({
            'Accept-Language': 'ar'
          }))
        },
        localCache: cache,
        async transformData(data) {
          return data?.data?.data || data?.data || data
        }
      })
  }

  return alovaInstance.Get(endpoint, {
    params: data,
    headers: {
      ...(localStorage.getItem('i18nextLng') ? ({
        'Accept-Language': localStorage.getItem('i18nextLng')
      }) : ({
        'Accept-Language': 'ar'
      }))
    },
    localCache: cache,
    async transformData(data) {
      return data?.data?.data || data?.data || data
    }
  })
}

export function postRequest(endpoint, body, params) {
  return alovaInstance.Post(endpoint,
    body,
    {
      params,
      headers: {
        ...(localStorage.getItem('i18nextLng') ? ({
          'Accept-Language': localStorage.getItem('i18nextLng')
        }) : ({
          'Accept-Language': 'ar'
        }))
      },
      localCache: "none",
      async transformData(data) {
        return {
          data,
        }
      }
    }
  )
}

export function patchRequest(endpoint, body, params) {
  return alovaInstance.Patch(endpoint,
    body,
    {
      params,
      headers: {
        ...(localStorage.getItem('i18nextLng') ? ({
          'Accept-Language': localStorage.getItem('i18nextLng')
        }) : ({
          'Accept-Language': 'ar'
        }))
      },
      localCache: "none",
      async transformData(data) {
        return {
          data,
        }
      }
    }
  )
}

export function searchCivilians(params, token) {

  // was /backend
  return alovaInstance.Get(`${HOST_API}/api/cspd/investigationBasicInfo`, {
    params,
    localCache: "none",
    async transformData(rawData, headers) {
      return rawData
    }
  })
}

export function apiLogin(data) {
  // was /backend
  return alovaInstance.Post(`${HOST_API}/api/jiacc-investigation/login`,
    data,
    {
      localCache: "none",
      async transformData(rawData, headers) {
        let responseHeaders = {};
        headers.forEach((header, key) => {
          responseHeaders[key] = header
        })
        return {
          data: rawData,
          token: responseHeaders['accesstoken'],
          dwtToken: responseHeaders['dwttoken']
        }
      },
    }
  )
}

export function uploadFileRequest(srcUrl, token, data, strategy = "form-data") {
  if (strategy === "form-data") {
    return alovaUploadInstance.Post(srcUrl,
      // Body
      data,
      // Config
      {
        headers: {
          token,
        },
      }
    )
  }
}
