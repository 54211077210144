import { useEffect, useState } from 'react';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export function useBackRoute(route) {
  const settings = useSettingsContext();

  useEffect(() => {
    settings.onSetBackRoute(route);

    return () => {
      settings.onResetBackRoute();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
