import styled from "@emotion/styled";
import { Box, Button, Card, CardHeader, Checkbox, Container, FormControlLabel, List, ListItem, ListItemButton, Menu, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from "@mui/material";
import { StyledActionButton } from "src/components/custom-styled-components";
import { useSettingsContext } from "src/components/settings";
import { useTable } from "src/components/table";
import Table from "src/components/table/table";
import { useBackRoute } from "src/hooks/use-back-route";
import { useLocales } from "src/locales";
import { useParams, useRouter } from "src/routes/hooks";
import { paths } from "src/routes/paths";
import { useGlobalDialogContext } from "src/components/global-dialog";
import ColorPicker from "./color-picker";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { LoadingScreen } from "src/components/loading-screen";
import { endpoints } from "src/utils/endpoints";
import { useRequest } from "alova";
import { memo } from "react";
import { LoadingButton } from "@mui/lab";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import SuggestionComplete from "./suggestion-complete";
import { fetchRequest, patchRequest } from "src/utils/axios";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

const defaultStyleValues = {
    fontWeight: 'normal',
    color: '#000',
    fontSize: 12
}


function EditStakeholderFieldsView() {
    const settings = useSettingsContext();
    const params = useParams();
    const { t, currentLang } = useLocales();
    const table = useTable({
        defaultDense: false,
        defaultOrder: 'asc',
        defaultOrderBy: 'id',
        defaultCurrentPage: 0,
        defaultRowsPerPage: 10,
        defaultSelected: [],
    });
    const [tableDesc, setTableDesc] = useState("")
    const [recordTitleTemplate, setRecordTitleTemplate] = useState("")
    const [recordTitleTemplateEn, setRecordTitleTemplateEn] = useState("")
    const [recordShowInFinancialDisclosure, setRecordShowInFinancialDisclosure] = useState(false)
    const [fieldsOptions, setFieldsOptions] = useState([])
    const [searchParams] = useSearchParams()
    const entityType = searchParams.get("entityType")

    const [data, setData] = useState([])
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingSave, setLoadingSave] = useState(false);

    // ** Vars
    const columns = useMemo(() => {
        return [
            {
                id: 'order', label: t('order'),
                minWidth: 100,
                renderRow: (row, col) => {
                    return <TextField value={findField(row.columnName)?.[col.id]}
                        onChange={e =>
                            changeFieldOption(row.columnName, col.id, e.target.value)
                        }
                    />
                }
            },
            { id: 'columnName', label: t('field_key'), minWidth: 150 },
            {
                id: 'labelAr', label: t('arabic_translation'), minWidth: 250,
                renderRow: (row, col) => {
                    return <TextField
                        value={findField(row.columnName)?.[col.id]}
                        onChange={e =>
                            changeFieldOption(row.columnName, col.id, e.target.value)
                        }
                    />
                }
            },
            {
                id: 'labelEn', label: t('english_translation'), minWidth: 250,
                renderRow: (row, col) => {
                    return <TextField value={findField(row.columnName)?.[col.id]}
                        onChange={e =>
                            changeFieldOption(row.columnName, col.id, e.target.value)
                        }
                    />
                }
            },
            {
                id: 'fontSize', label: t('font_size'), minWidth: 100,
                renderRow: (row, col) => {
                    return <Select fullWidth value={findField(row.columnName)?.[col.id]} onChange={e =>
                        changeFieldOption(row.columnName, col.id, e.target.value)
                    }>
                        {[12, 14, 18].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                }
            },
            {
                id: 'color', label: t('color'), minWidth: 70,
                renderRow: (row, col) => {
                    return <ColorPicker value={findField(row.columnName)?.[col.id]} onChange={(val) => {
                        changeFieldOption(row.columnName, col.id, val)
                    }} />
                }
            },
            {
                id: 'fontWeight', label: t('bold_font'), minWidth: 70,
                renderRow: (row, col) => {
                    return <Checkbox checked={findField(row.columnName)?.[col.id] === "bold"}
                        onChange={e =>
                            changeFieldOption(row.columnName, col.id, e.target.checked ? "bold" : "normal")
                        }
                    />
                }
            },
            // {
            //     id: 'labelKey', label: t('labelKey'), minWidth: 160,
            //     renderRow: (row, col) => {
            //         return <Checkbox checked={findField(row.columnName)?.[col.id]}
            //             onChange={e =>
            //                 changeFieldOption(row.columnName, col.id, e.target.checked, true)
            //             }
            //         />
            //     }
            // },
            {
                id: 'show', label: t('is_active'), minWidth: 70,
                renderRow: (row, col) => {
                    return <>
                        <Checkbox checked={findField(row.columnName)?.[col.id]}
                            onChange={e =>
                                changeFieldOption(row.columnName, col.id, e.target.checked)
                            }
                        />
                    </>
                }
            },
            {
                id: 'showInFinancialDisclosure', label: t('table_show_record_financial_disclosure'), minWidth: 70,
                renderRow: (row, col) => {
                    return <>
                        <Checkbox checked={findField(row.columnName)?.[col.id]}
                            onChange={e =>
                                changeFieldOption(row.columnName, col.id, e.target.checked)
                            }
                        />
                    </>
                }
            },
            // {
            //     type: 'actions',
            //     label: t('action'),
            //     align: 'center',
            //     minWidth: 100,
            // },
        ]
    }, [rows, fieldsOptions]);

    const findField = useCallback((columnName) => {
        return fieldsOptions.find(option => option.columnName === columnName)
    }, [fieldsOptions])

    const changeFieldOption = useCallback((columnName, option, value, onlyOne) => {
        console.log("columnName", columnName)
        console.log("option", option)
        console.log("value", value)
        setFieldsOptions(prev => {
            let newFieldsOptions = prev.slice();
            const targetField = findField(columnName)
            const editedField = {
                ...targetField,
                [option]: value
            }


            if (onlyOne && value) {
                newFieldsOptions = newFieldsOptions.map((item) => ({
                    ...item,
                    [option]: !value,
                }))
            }

            newFieldsOptions = newFieldsOptions.filter(option => option.columnName !== columnName)

            newFieldsOptions.push(editedField)


            return newFieldsOptions;
        })
    }, [fieldsOptions])

    const prepareColumnToSend = (columnOptions) => {
        const { columnName, labelAr, labelEn, show, showInFinancialDisclosure, order, labelKey, ...styling } = columnOptions
        if (styling) {
            delete styling.styling
        }
        let columnToSend = {
            columnName,
            labelAr: labelAr || columnName,
            labelEn: labelEn || columnName,
            show: show || false,
            showInFinancialDisclosure: showInFinancialDisclosure || false,
            order: order || 1,
            styling: {
                ...defaultStyleValues,
                ...styling
            },
        }
        return columnToSend
    }



    const onSaveSingleField = useCallback((columnName) => {
        let foundField = findField(columnName)
        if (foundField) {
            let dataToSend = {}
            const newCols = rows.slice();
            let colIdx = newCols.findIndex(obj => obj.columnName === columnName)
            if (foundField.labelKey) {
                dataToSend.labelKey = columnName;
            }
            newCols[colIdx] = prepareColumnToSend(foundField)

            dataToSend.columns = newCols;

            // console.log("dataToSend.columns", dataToSend.columns);

            sendSave(dataToSend)
        }
    }, [rows, fieldsOptions])

    const onSaveAllFields = useCallback(() => {
        let columnsToSend = rows.slice()

        fieldsOptions.forEach((row) => {
            // sendSave
            let foundField = findField(row.columnName)
            if (foundField) {
                let colIdx = columnsToSend.findIndex(obj => obj.columnName === row.columnName)
                columnsToSend[colIdx] = prepareColumnToSend(foundField)
            }
        })
        let dataToSend = {}

        const labelKey = fieldsOptions.find(col => col.labelKey)?.columnName

        if (labelKey) {
            dataToSend.labelKey = labelKey;
        }

        dataToSend.columns = columnsToSend
        dataToSend.description = tableDesc
        dataToSend.template = recordTitleTemplate
        dataToSend.template_en = recordTitleTemplateEn
        dataToSend.includedInFinancialDisclosure = recordShowInFinancialDisclosure


        sendSave(dataToSend)
    }, [rows, fieldsOptions, tableDesc, recordTitleTemplate, recordTitleTemplateEn,recordShowInFinancialDisclosure])


    const sendSave = useCallback(async (body) => {
        setLoadingSave(true)
        try {
            await patchRequest(endpoints.internal.updateInvestigationTableInfo(params.id), body);
            toast(t('saved_success'), {
                icon: '✅',
            })
        } catch (err) {
            toast(t('error_occured'), {
                icon: '❌',
            })
        }
        finally {

            setLoadingSave(false)
        }
    }, [])


    useEffect(() => {
        fetchRequest(endpoints.internal.investigationTableInfo(params.id)).then((data) => {
            setData(data)
        }).catch(err => {
            console.log("Error is: ", err)
            toast(t('server_error'), {
                icon: '❌',
            })
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (data?.columns) {
            let cols = data.columns.map((item) => {
                return {
                    fontSize: item?.styling?.fontSize || defaultStyleValues?.fontSize,
                    fontWeight: item?.styling?.fontWeight || defaultStyleValues?.fontWeight,
                    color: item?.styling?.color || defaultStyleValues?.color,
                    labelKey: item.columnName === data.labelKey,
                    ...item,
                }
            })
            setRows(cols)
            setFieldsOptions(cols)
        }

        setTableDesc(data?.description || "")
        setRecordTitleTemplate(data?.template || "")
        setRecordTitleTemplateEn(data?.template_en || "")
        setRecordShowInFinancialDisclosure(data?.includedInFinancialDisclosure ||false)
    }, [data])

    const optionsToFillSuggestion = data?.columns?.map(item => ({
        label: `${item.columnName} (${findField(item.columnName)?.[currentLang?.value === "ar" ? 'labelAr' : 'labelEn']})`,
        value: item.columnName
    }))


    useBackRoute(paths.dashboard.system_settings.stakeholders_list + (entityType === "establishment" ? `?entityType=establishment` : ''));


    if (loading || rows.length === 0) return <LoadingScreen />
    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <CardHeader title={`${t('edit_fields')}: ${data.tableName}`} />

            <Card>
                <Box sx={{ py: 2, }}>
                    <Grid2
                        container
                        spacing={1}
                    >
                        <Grid2 xs={12} sm={6}>
                            <TextField
                                label={t("table_desc")}
                                value={tableDesc}
                                onChange={e => setTableDesc(e.target.value)}
                                fullWidth
                            />
                        </Grid2>
                        <Grid2 xs={0} sm={6}>
                        </Grid2>
                        <Grid2 xs={12} sm={6}>
                            <TextField
                                label={t("table_view_record_template")}
                                value={recordTitleTemplate}
                                onChange={e => setRecordTitleTemplate(e.target.value)}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <Box>
                                            <SuggestionComplete
                                                label={t('fill_suggestion')}
                                                options={optionsToFillSuggestion}
                                                handleChooseOption={(value) => setRecordTitleTemplate(prev => `${prev}${prev[prev.length - 1] === " " ? "" : " "}{{${value}}}`)}
                                            />
                                        </Box>
                                    )
                                }}
                            />
                        </Grid2>
                        <Grid2 xs={12} sm={6}>
                            <TextField
                                label={t("table_view_record_template_en")}
                                value={recordTitleTemplateEn}
                                onChange={e => setRecordTitleTemplateEn(e.target.value)}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <Box>
                                            <SuggestionComplete
                                                label={t('fill_suggestion')}
                                                options={optionsToFillSuggestion}
                                                handleChooseOption={(value) => setRecordTitleTemplateEn(prev => `${prev}${prev[prev.length - 1] === " " ? "" : " "}{{${value}}}`)}
                                            />
                                        </Box>
                                    )
                                }}
                            />
                        </Grid2>
                        <Grid2 xs={12}>
                            <FormControlLabel
                                label={t("table_show_record_financial_disclosure")}
                                control={
                                    <Checkbox
                                        checked={recordShowInFinancialDisclosure}
                                        onChange={e => {
                                            setRecordShowInFinancialDisclosure(e.target.checked)
                                        }}
                                    />
                                }
                            />
                        </Grid2>
                    </Grid2>
                </Box>
                <Table
                    {...table}
                    columns={columns}
                    rows={rows}
                    tableContainerProps={{
                        sx: {
                            minHeight: '400px'
                        }
                    }}
                // renderActions={(row) => (
                //     <Box
                //         sx={{
                //             display: 'flex',
                //             flexDirection: 'row',
                //             alignItems: 'center',
                //             justifyContent: 'center',
                //             flexWrap: 'wrap',
                //             gap: 1,
                //         }}
                //     >
                //         <LoadingButton
                //             variant="contained"
                //             color="secondary"
                //             size="small"
                //             loading={loadingSave}
                //             onClick={() => onSaveSingleField(row.columnName)}
                //         >
                //             {t('save')}
                //         </LoadingButton>
                //     </Box>
                // )}
                />

                <Box sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                }}>

                    <LoadingButton
                        variant="contained"
                        color="secondary"
                        loading={loadingSave}
                        onClick={() => onSaveAllFields()}
                        sx={{
                            width: 200
                        }}
                    >
                        {t('save')}
                    </LoadingButton>
                </Box>
            </Card>
        </Container>
    )
}

export default memo(EditStakeholderFieldsView)