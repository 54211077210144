import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import SystemSettingsPage from 'src/pages/dashboard/system-settings/system-settings';
import StakeholdersListPage from 'src/pages/dashboard/system-settings/stakeholders-list';
import EditStakeholderFieldsPage from 'src/pages/dashboard/system-settings/edit-stakeholder-fields';
import ScreensListPage from 'src/pages/dashboard/system-settings/screens-list';
import RolesListPage from 'src/pages/dashboard/system-settings/roles-list';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/one'));
const SettingsPage = lazy(() => import('src/pages/dashboard/settings/settings'));
const NotificationCenter = lazy(() => import('src/pages/dashboard/notifications/list'));
// Tasks
const TaskListPage = lazy(() => import('src/pages/dashboard/tasks/list'));
const TaskAssignPage = lazy(() =>
  import('src/pages/dashboard/tasks/assign')
);

// Direct Investigation
const DirectInvestigationPage = lazy(() =>
  import('src/pages/dashboard/investigation/direct-investigation')
);
const DirectProfilePage = lazy(() => import('src/pages/dashboard/investigation/direct-profile'));

// Case Investigation
const CaseProfilePage = lazy(() => import('src/pages/dashboard/investigation/case-profile'));

const FinancialDisclosureListPage = lazy(() =>
  import('src/pages/dashboard/financial-disclosure/list')
);
const StrictTrackingListPage = lazy(() => import('src/pages/dashboard/strict-tracking/list'));
const ManagementAuthPage = lazy(() => import('src/pages/dashboard/management-auth/management-auth'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'notifications',
        element: <NotificationCenter />,
      },
      {
        path: 'tasks',
        children: [
          {
            index: true,
            element: <TaskListPage />,
          },
          {
            path: ':id/assign',
            element: <TaskAssignPage />,
          },
        ],
      },
      {
        path: 'investigation',
        children: [
          {
            path: 'direct-investigation',
            element: <DirectInvestigationPage />,
          },
          {
            path: 'direct-profile/:national_id',
            element:
              <DirectProfilePage />
          },
          {
            path: 'case-profile/:id',
            element:
              <CaseProfilePage />
          },
        ],
      },
      {
        path: 'financial-disclosure',
        children: [
          {
            index: true,
            element: <FinancialDisclosureListPage />,
          },
        ],
      },
      {
        path: 'strict-tracking',
        children: [
          {
            index: true,
            element: <StrictTrackingListPage />,
          },
        ],
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
      {
        path: 'system-settings',
        children: [
          {
            index: true,
            element: <SystemSettingsPage />,
          },
          {
            path: 'stakeholders-list',
            element: <StakeholdersListPage />,
          },
          {
            path: 'stakeholder-fields/:id',
            element: <EditStakeholderFieldsPage />,
          },
          {
            path: 'screens-list',
            element: <ScreensListPage />,
          },
          {
            path: 'roles-list',
            element: <RolesListPage />,
          },
        ],
      },
      {
        path: 'management-auth',
        element: <ManagementAuthPage />,
      },
    ],
  },
];
