import { Suspense, useEffect } from 'react';
import { Navigate, Outlet, matchPath, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { LoadingScreen } from 'src/components/loading-screen';
import RootLayout from 'src/layouts/dashboard/root-layout';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    {
      path: '',
      element: (
        <RootLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </RootLayout>
      ),
      children: [
        // Auth routes
        ...authRoutes,

        // Dashboard routes
        ...dashboardRoutes,

        // Main routes
        ...mainRoutes,
      ]
    },

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
