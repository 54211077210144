import { Box, Card, CardHeader, Container } from "@mui/material";
import { StyledActionButton } from "src/components/custom-styled-components";
import { useSettingsContext } from "src/components/settings";
import { useTable } from "src/components/table";
import Table from "src/components/table/table";
import { useBackRoute } from "src/hooks/use-back-route";
import { useLocales } from "src/locales";
import { useRouter } from "src/routes/hooks";
import { paths } from "src/routes/paths";
import StakeholderTables from "./modals/stakeholder-tables";
import { useGlobalDialogContext } from "src/components/global-dialog";
import { useRequest } from "alova";
import { fetchRequest } from "src/utils/api";
import { endpoints } from "src/utils/endpoints";
import { LoadingScreen } from "src/components/loading-screen";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";


export default function StakeholdersListView() {
    const settings = useSettingsContext();
    const globalDialog = useGlobalDialogContext();
    const router = useRouter();
    const [searchParams] = useSearchParams()
    const entityType = searchParams.get("entityType")
    const { t } = useLocales();
    const table = useTable({
        defaultDense: false,
        defaultOrder: 'asc',
        defaultOrderBy: 'id',
        defaultCurrentPage: 0,
        defaultRowsPerPage: 10,
        defaultSelected: [],
    });
    const {
        loading,
        send,
        data,
    } = useRequest(
        fetchRequest(entityType === "establishment" ? endpoints.internal.investigationEstablishmentEntities : endpoints.internal.investigationEntities)
    );
    const [rows, setRows] = useState([])


    // ** Vars
    const columns = [
        { id: 'name', label: t('stakeholder_name'), minWidth: 200 },
        { id: 'code', label: t('stakeholder_key'), minWidth: 200 },
        {
            type: 'actions',
            label: t('action'),
            align: 'center',
            minWidth: 160,
        },
    ];

    const onViewStakeholderTablesClick = (stakeholderId) => {
        console.log("stakeholderId", stakeholderId)
        globalDialog.onOpen({
            dialogProps: {
                maxWidth: 'md',
            },
            title: t('view_tables'),
            content: (
                <StakeholderTables stakeholderId={stakeholderId} entityType={entityType} />),
        });
    }


    useBackRoute(paths.dashboard.system_settings.root);

    if (loading) return <LoadingScreen />

    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <CardHeader title={t('stakeholders_list')} />

            <Card>
                <Table
                    {...table}
                    columns={columns}
                    rows={data}
                    renderActions={(row) => (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                gap: 1,
                            }}
                        >
                            <StyledActionButton
                                onClick={() =>
                                    onViewStakeholderTablesClick(row._id)
                                }
                                variant="outlined"
                                color="secondary"
                                size="small"
                            >
                                {t('view_tables')}
                            </StyledActionButton>
                        </Box>
                    )}
                />
            </Card>
        </Container>
    )
}