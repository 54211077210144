import PropTypes from "prop-types";
import { useFormContext, Controller, useController } from "react-hook-form";
import { useRequest } from "alova";
// @mui
import FormHelperText from "@mui/material/FormHelperText";
// utils
import wrapRequest from "src/utils/wrapRequest";
import { uploadFileRequest } from "src/utils/api";
//
import { UploadAvatar, Upload, UploadBox, UploadField } from "../upload";
import { useState } from "react";
import { useLocales } from "src/locales";

// ----------------------------------------------------------------------

export function RHFUploadAvatar({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <UploadAvatar error={!!error} file={field.value} {...other} />

          {!!error && (
            <FormHelperText error sx={{ px: 2, textAlign: "center" }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

RHFUploadAvatar.propTypes = {
  name: PropTypes.string,
};

// ----------------------------------------------------------------------

export function RHFUploadBox({ name, sx, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UploadBox
          files={field.value}
          error={!!error}
          sx={{
            ...sx,
          }}
          {...other}
        />
      )}
    />
  );
}

RHFUploadBox.propTypes = {
  name: PropTypes.string,
};

// ----------------------------------------------------------------------

export function RHFUpload({ name, multiple, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        multiple ? (
          <Upload
            multiple
            accept={{ "image/*": [] }}
            files={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        ) : (
          <Upload
            accept={{ "image/*": [] }}
            file={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        )
      }
    />
  );
}

// ----------------------------------------------------------------------
export function RHFUploadField({
  name,
  sx,
  uploadStrategy,
  destinationApi,
  destinationApiToken,
  destinationExtraArgs,
  responseFileNameKey,
  allowedExtensions,
  minFileSize,
  maxFileSize,
  ...other
}) {
  const { t } = useLocales();
  const { control, getValues, setValue, setError, clearErrors } = useFormContext();
  const { field, fieldState: { error } } = useController({
    name,
    control,
  })
  const {
    loading,
    send,
    data: options,
  } = useRequest(
    (data) => uploadFileRequest(destinationApi, destinationApiToken, data, uploadStrategy), {
    immediate: false,
  });



  const uploadFile = async (file) => {
    const fileSizeKiloBytes = file.size / 1024;

    let error = "";


    const getSizeInMB = (size) => {
      return parseFloat(Number(size) / 1024).toFixed(2)
    }

    const parseExtension = (extension) => {
      if (extension.includes("/")) {
        return extension.split("/")[1]
      } else {
        return extension
      }
    }


    if (allowedExtensions?.length > 0 && !allowedExtensions?.includes(file?.type)) {
      error = t("extension_not_allowed", {
        allowed_extensions: allowedExtensions.slice().map(ext => {
          return parseExtension(ext)
        }).join(", "),
        extension: parseExtension(file.type)
      });
    } else if (minFileSize && fileSizeKiloBytes < Number(minFileSize)) {
      error = t("file_size_cant_be_less_than", {
        size: getSizeInMB(minFileSize),
        unit: t("megabyte")
      });
    } else if (maxFileSize && fileSizeKiloBytes > Number(maxFileSize)) {
      error = t("file_size_cant_be_larger_than", {
        size: getSizeInMB(maxFileSize),
        unit: t("megabyte")
      });
    }

    if (error) {
      setError(name, {
        message: error
      })
      return
    } else {
      clearErrors(name)
    }


    if (uploadStrategy === "form-data") {
      const formData = new FormData();

      // Append File
      formData.append("file", file)

      // Append Extra Args
      if (Object.keys(destinationExtraArgs).length > 0) {
        Object.keys(destinationExtraArgs).forEach((key) => {
          formData.append(key, destinationExtraArgs[key])
        })
      }

      wrapRequest(
        send(formData),
        (data) => {
          field?.onChange(data[responseFileNameKey])
          setValue(name + '_label', file.name)
        },
        (error) => {
        })


    }
  }

  return (
    <div>
      <UploadField
        error={!!error}
        file={getValues(name + '_label')}
        onDrop={(acceptedFiles) => uploadFile(acceptedFiles[0])}
        loading={loading}
        sx={{
          ...sx,
          ...(error && {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: (theme) =>
                `${theme.palette.error.main} !important`,
            },
          }),
        }}
        {...other}
      />


      {!!error && (
        <FormHelperText error sx={{ px: 2 }}>
          {error.message}
        </FormHelperText>
      )}
    </div>
  );
}

RHFUploadField.propTypes = {
  name: PropTypes.string,
  sx: PropTypes.object,
  uploadStrategy: PropTypes.string,
  destinationApi: PropTypes.string,
  destinationApiToken: PropTypes.string,
  destinationExtraArgs: PropTypes.object,
  responseFileNameKey: PropTypes.string,
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
  minFileSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxFileSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RHFUpload.propTypes = {
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  sx: PropTypes.object,
};
