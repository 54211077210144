import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const GlobalDataContext = createContext({});

export const useGlobalDataContext = () => {
  const context = useContext(GlobalDataContext);

  if (!context) throw new Error('useGlobalDataContext must be use inside GlobalDataProvider');

  return context;
};
