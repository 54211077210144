import { HOST_API } from "src/config-global"

// was /backend
export const internalAPIUrl = HOST_API + '/api'

export const endpoints = {
    internal: {
        lookupByCode: (code, parent) => `${internalAPIUrl}/lookups/lookup/${code}${parent ? `/${parent}` : ""}`,
        searchCivilians: `${internalAPIUrl}/cspd/investigationBasicInfo`,
        searchCompanies: `${internalAPIUrl}/jiacc-investigation/investigateCCDInfo`,
        // User
        basicLogin: `${internalAPIUrl}/user/login`,
        me: `${internalAPIUrl}/user`,
        changePassword: `${internalAPIUrl}/user/resetPassword`,
        getUserDirectManager: `${internalAPIUrl}/user/getManagerByUser`,
        getDirectManagerByUserID: (id) => `${internalAPIUrl}/user/getManagerByUser/${id}`,
        findUsersUnderMe: `${internalAPIUrl}/user/findByDirectManager`,
        getUserDirectManagerByUserGUID: (GUID) => `${internalAPIUrl}/user/getManagerByUser/${GUID}`,
        checkExternalSession: `${internalAPIUrl}/user/checkSession`,
        //
        tasksCount: `${internalAPIUrl}/jiacc-investigation/tasksCount`,
        getRelationDegrees: `${internalAPIUrl}/jiacc-investigation/GetFamilyRelationsDegrees`,
        getRelationsByDegree: `${internalAPIUrl}/jiacc-investigation/GetFamilyRelationsByDegree`,
        getRelationByCode: `${internalAPIUrl}/jiacc-investigation/GetFamilyRelationsByCode`,
        cspdData: (nationalId) => `${internalAPIUrl}/cspd/identityInfo/${nationalId}`,
        dvldOwnerPerson: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getDVLDOwnerPerson/${nationalId}`,
        dvldOwnerEstablishment: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getDVLDOwnerEstablishment/${nationalId}`,
        dlsOwner: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getDLSOwner/${nationalId}`,
        ccdInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getCCDInfo/${nationalId}`,
        accInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getACCInfo/${nationalId}`,
        dcmrInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getDCMRInfo/${nationalId}`,
        SSCActiveInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getSSCActiveInfo/${nationalId}`,
        hiaInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getHiaInfo/${nationalId}`,
        moheStaff: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getMoheStaff/${nationalId}`,
        moheStudentAbroad: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getMoheStudentAbroad/${nationalId}`,
        moheStudentJo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getMoheStudentJo/${nationalId}`,
        molPermits: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getMolPermits/${nationalId}`,
        nafHemayehInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getNafHemayehInfo/${nationalId}`,
        nafTakafolInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getNafTakafolInfo/${nationalId}`,
        moePSInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getMOEPSInfo/${nationalId}`,
        moeInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getMOEInfo/${nationalId}`,
        mosdJovInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getMOSDJovInfo/${nationalId}`,
        MOSDMKBenefListInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getMOSDMKBenefListInfo/${nationalId}`,
        MOSDNurseryInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getMOSDNurseryInfo/${nationalId}`,
        CSBRhcViweNew: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getCSBRhcViweNew/${nationalId}`,
        SJDInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getSJD/${nationalId}`,
        SJDErthInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getSJDErth/${nationalId}`,
        SJDMarriageInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getSJDMarriage/${nationalId}`,
        ZFAytamInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getZFAytam/${nationalId}`,
        ZFPaidFamiliesInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getZFPAIDFAMILIES/${nationalId}`,
        ZFZakahServiceInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getZFZakahService/${nationalId}`,
        PSDInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getPSD/${nationalId}`,
        HUDCInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getHUDC/${nationalId}`,
        HCDServiceInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getHCDService/${nationalId}`,
        JCCasesInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getJC_Cases/${nationalId}`,
        DEFInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getDEF/${nationalId}`,
        OFCDMorabahatInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getOFCDMorabahat/${nationalId}`,
        OFCDOrphDepositsInfo: (nationalId) => `${internalAPIUrl}/jiacc-investigation/getOFCDOrphDeposits/${nationalId}`,
        Aseza_GetInvestmentData_ByNatNo: `${internalAPIUrl}/jiacc-investigation/Aseza_GetInvestmentData_ByNatNo`,
        Aseza_GetREG_DataByNatNo: `${internalAPIUrl}/jiacc-investigation/Aseza_GetREG_DataByNatNo`,
        ASEZA_Get_Company_Irregularities_ByNatNo: `${internalAPIUrl}/jiacc-investigation/ASEZA_Get_Company_Irregularities_ByNatNo`,
        investigationEntities: `${internalAPIUrl}/entity-management/findJIACCEntities`,
        investigationEstablishmentEntities: `${internalAPIUrl}/entity-management/findJIACCEntitiesEstablishments`,
        investigationEntityTables: (id) => `${internalAPIUrl}/entity-management/findJIACCTableInfoByEntityId/${id}`,
        investigationTableInfo: (id) => `${internalAPIUrl}/entity-management/getJIACCTableInfo/${id}`,
        updateInvestigationTableInfo: (id) => `${internalAPIUrl}/entity-management/updateJIACCTableInfo/${id}`,
        investigationTableData: `${internalAPIUrl}/jiacc-investigation/tableData`,
        investagationGSBData: (apiName) => `${internalAPIUrl}/jiacc-investigation/${apiName}`,
        nationalitiesLookup: `${internalAPIUrl}/lookups/nationalities2`,
        // Task List
        getTaskList: `${internalAPIUrl}/jiacc-investigation/findInvestigatorsTasks`,
        getSingleTask: (GUID) => `${internalAPIUrl}/jiacc-investigation/findInvestigationTask/${GUID}`,
        assignTask: `${internalAPIUrl}/jiacc-investigation/assignTaskToUser`,
        getPersonalImage: (nationalNumber) => `${internalAPIUrl}/cspd/getPersonalImage/${nationalNumber}`,
        sendRecommendation: `${internalAPIUrl}/jiacc-investigation/sendRecommendationProfile`,
        getTaskRecommendations: (GUID) => `${internalAPIUrl}/jiacc-investigation/getRecommendationsByTaskId/${GUID}`,
        // Case Investigation
        getCaseInvestigationRequest: `${internalAPIUrl}/jiacc-investigation/getCaseInvestigationRequest`,
        getLatestInvestigationProfile: `${internalAPIUrl}/jiacc-investigation/getLatestInvestigationProfile`,
        getInvRequestInvestigationProfile: (reqGUID) => `${internalAPIUrl}/jiacc-investigation/getInvRequestInvestigationProfile/${reqGUID}`,
        saveInvestigationProfile: `${internalAPIUrl}/jiacc-investigation/saveInvestigationProfile`,
        saveAndReplaceInvestigationProfile: (investigationRequestGUID) => `${internalAPIUrl}/jiacc-investigation/saveInvestigationProfile/${investigationRequestGUID}`,
        changeTaskStatus: (investigationRequestGUID) => `${internalAPIUrl}/jiacc-investigation/updateInvestigationRequest/${investigationRequestGUID}`,
        enableViewPrevInvestigations: `${internalAPIUrl}/jiacc-investigation/enableViewPrevInvestigations`,
        getPreviousInvestigationProfiles: `${internalAPIUrl}/jiacc-investigation/getPreviousInvestigationProfiles`,
        getAllInvestigationProfiles: `${internalAPIUrl}/jiacc-investigation/getAllInvestigationProfiles`,
        // Screens & Actions
        getAllScreens: `${internalAPIUrl}/jiacc-investigation/getAllScreens`,
        getScreenById: (screenId) => `${internalAPIUrl}/jiacc-investigation/getScreenById/${screenId}`,
        getScreenByCode: (screenCode) => `${internalAPIUrl}/jiacc-investigation/getScreenByCode/${screenCode}`,
        createScreen: `${internalAPIUrl}/jiacc-investigation/createScreen`,
        editScreenById: (screenId) => `${internalAPIUrl}/jiacc-investigation/editScreenById/${screenId}`,
        deleteScreenById: (screenId) => `${internalAPIUrl}/jiacc-investigation/deleteScreen/${screenId}`,
        // Role Permissions
        getAllRoles: `${internalAPIUrl}/lookups/lookup/21`,
        getUserPermissions: `${internalAPIUrl}/user/permissions`,
        getPermissionsByRoleId: (roleId) => `${internalAPIUrl}/jiacc-investigation/getPermissionsByRole/${roleId}`,
        editRolePermissions: `${internalAPIUrl}/jiacc-investigation/editRolePermissions`,
        // Profile Messaging
        sendProfileMessage: `${internalAPIUrl}/messaging/createMessage`,
        getProfileMessagesByInvId: reqGUID => `${internalAPIUrl}/messaging/findByInvestigationRequest/${reqGUID}`,
        // Notifications
        getAllNotifications: `${internalAPIUrl}/notifications/getNotifications`,
        getUnreadNotifications: `${internalAPIUrl}/notifications/getUnreadNotifications`,
        getUnreadNotificationsCount: `${internalAPIUrl}/notifications/getUnreadNotificationsCount`,
        setNotificationAsRead: (GUID) => `${internalAPIUrl}/notifications/setNotificationAsRead/${GUID}`,
        // Convert To information
        convertToInformation: `${internalAPIUrl}/jiacc-investigation/convertToInfo`,
        // Watch List
        getWatchList: `${internalAPIUrl}/watchlist`,
        createWatchList: `${internalAPIUrl}/watchlist`,
        createBulkWatchList: `${internalAPIUrl}/watchlist/bulk`,
        deleteWatchList: (GUID) => `${internalAPIUrl}/watchlist/${GUID}`,
        // Financial Disclosure
        createFinancialDisclosure: `${internalAPIUrl}/financial-disclosure/mainFinancialDisclosure`,
        getFinancialDisclosureList: `${internalAPIUrl}/financial-disclosure/mainFinancialDisclosure`,
        // Cases and Complaints
        getCasesByNatNo: (NAT_NO) => `${internalAPIUrl}/jiacc-investigation/getCasesData/${NAT_NO}`,
        getComplaintsByNatNo: (NAT_NO) => `${internalAPIUrl}/jiacc-investigation/getInformationData/${NAT_NO}`,
        isAddedToWatchList: (NAT_NO) => `${internalAPIUrl}/watchlist/checkIfAdded/${NAT_NO}`,
        deleteWatchListByNatNo: (NAT_NO) => `${internalAPIUrl}/watchlist/byNatNo/${NAT_NO}`,
        getDVLDHistoryByChasNum: (CHAS_NUM) => `${internalAPIUrl}/jiacc-investigation/getDVLDHistoricalTransactionsByChasNum/${CHAS_NUM}`,
        getDLSHistoryByDlsKey: (DLS_KEY) => `${internalAPIUrl}/jiacc-investigation/getDLSHistoricalTransactionsByDLSKey/${DLS_KEY}`,
        getLatestInvestigationDate: (NAT_NO) => `${internalAPIUrl}/jiacc-investigation/latestInvestigationDate/${NAT_NO}`,
        getWealthChanges: `${internalAPIUrl}/jiacc-investigation/getWealthChanges`,
        // Delegation
        getUsers: `${internalAPIUrl}/user/getUsers`,
        getDelegations: `${internalAPIUrl}/jiacc-investigation/findDelegations`,
        createDelegation: `${internalAPIUrl}/jiacc-investigation/createEmployeeDelegation`,
        deleteDelegationById: (id) => `${internalAPIUrl}/jiacc-investigation/delegation/${id}`,
        getIdentityInfoWithoutQuery: (natNo) => `${internalAPIUrl}/cspd/getIdentityInfoWithoutQuery/${natNo}`,
        findInvalidPersonsFromDWCSPDAndPSD: `${internalAPIUrl}/watchlist/findInvalidPersonsFromDWCSPDAndPSD`,
    }
}
