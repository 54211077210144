import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'Content-Type': 'application/json',
    // Token: HOST_API_TOKEN,
  },
});

export function axiosFetcher(url, method, payload, headers) {
  return axiosInstance.request({
    url,
    method,
    headers,
    ...(method === "GET" ? ({
      params: payload,
    }) : {
      data: payload
    })
  }).then((res) => {
    return res.data
  })
}

// axiosInstance.interceptors.response.use(
//   (res) => res
// );

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export async function fetchRequest(endpoint, data, method = "GET", cbBefore) {
  if(cbBefore) await cbBefore();
  if (method.toUpperCase() === "POST") {
    return await axiosInstance.post(endpoint, data).then(data => {
      return data?.data?.data || data?.data || data
    })
  }

  return await axiosInstance.get(endpoint, { params: data }).then(data => {
    return data?.data?.data || data?.data || data
  })
}

export async function patchRequest(endpoint, data) {
  return await axiosInstance.patch(endpoint, data).then(data => {
    return data?.data?.data || data?.data || data
  })
}

export async function postRequest(endpoint, data) {
  return await axiosInstance.post(endpoint, data).then(data => {
    return data?.data?.data || data?.data || data
  })
}

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/Auth/getUserInfo',
    login: '/api/Auth/login',
    register: '/api/addTenant',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
