import PropTypes from 'prop-types';
import { useMemo, useState, useEffect, useCallback } from 'react';
//
import { GlobalDataContext } from './global-data-context';
import { fetchRequest } from 'src/utils/api';
import { fetchRequest as axiosFetchRequest } from 'src/utils/axios';
import { useRequest } from 'alova';
import { endpoints } from 'src/utils/endpoints';
import { RELIGIONS, TABLES_IDS } from 'src/config-global';

// ----------------------------------------------------------------------

export function GlobalDataProvider({ children }) {
  // ** State
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  // Requests
  const { send: sendRelationDegrees } = useRequest(() => fetchRequest(endpoints.internal.getRelationDegrees), { immediate: false })
  // const { send: sendRelationDegrees } = useRequest(() => fetchRequest(endpoints.internal.lookupByCode(9)), { immediate: false })
  // const { send: sendRelationships } = useRequest((parent) => fetchRequest(endpoints.internal.lookupByCode(parent)), { immediate: false })
  const { send: sendTableInfo } = useRequest((tableId) => fetchRequest(endpoints.internal.investigationTableInfo(tableId)), { immediate: false })
  // const { send: sendMainEntities } = useRequest(getMainEntities(), { immediate: false })
  const { send: sendGovEstablishmentStakeholders } = useRequest(
    fetchRequest(endpoints.internal.investigationEstablishmentEntities),
    { immediate: false }
  )
  const { send: sendSystemScreens } = useRequest(
    fetchRequest(endpoints.internal.getAllScreens),
    { immediate: false }
  )
  const { send: sendGovStakeholders } = useRequest(
    fetchRequest(endpoints.internal.investigationEntities),
    { immediate: false }
  )
  const { send: sendNationalitiesLookup } = useRequest(
    fetchRequest(endpoints.internal.nationalitiesLookup),
    { immediate: false }
  )
  const { send: sendTaskPriorityLookup } = useRequest(
    fetchRequest(endpoints.internal.lookupByCode(12)),
    { immediate: false }
  )

  const { send: sendTaskStatusLookup } = useRequest(
    fetchRequest(endpoints.internal.lookupByCode(16)),
    { immediate: false }
  )

  // ** Functions
  const handleFetchGlobalData = async () => {
    // const [mainEntities] = await Promise.all([sendMainEntities()])


    // --
    const [cspdTableInfo] = await Promise.all([sendTableInfo(TABLES_IDS.CSPD)]);
    const [psdTableInfo] = await Promise.all([sendTableInfo(TABLES_IDS.PSD)]);
    const [ccdTableInfo] = await Promise.all([sendTableInfo(TABLES_IDS.CCD)]);
    const [taskPriorities] = await Promise.all([sendTaskPriorityLookup()])
    const [taskStatuses] = await Promise.all([sendTaskStatusLookup()])
    const [systemScreens] = await Promise.all([sendSystemScreens()])
    const [nationalitiesLookup] = await Promise.all([sendNationalitiesLookup()])
    // const [govStakeholders] = await Promise.all([sendGovStakeholders().then(async data => {
    //   const govStakeholdersWithTables = await Promise.all(data.map(async (item, index) => ({
    //     ...item,
    //     tables: (await axiosFetchRequest(
    //       endpoints.internal.investigationEntityTables(item._id))
    //       .then(tables => {
    //         return (tables || []);
    //       })
    //       .catch(err => console.log(`Error Fetching Stakeholder Table Data: ${item.Code}`, err))) || []
    //   })))

    //   return govStakeholdersWithTables;
    // })])
    // const [govEstablishmentStakeholders] = await Promise.all([sendGovEstablishmentStakeholders().then(async data => {
    //   const govStakeholdersWithTables = await Promise.all(data.map(async (item, index) => ({
    //     ...item,
    //     tables: (await axiosFetchRequest(
    //       endpoints.internal.investigationEntityTables(item._id))
    //       .then(tables => {
    //         return (tables || []);
    //       })
    //       .catch(err => console.log(`Error Fetching Relations By Degree: ${item.Code}`, err))) || []
    //   })))

    //   return govStakeholdersWithTables;
    // })])
    // const [relationDegrees] = await Promise.all([sendRelationDegrees().then(async data => {
    //   const relationDeggreeWithSubs = await Promise.all(data.map(async (item, index) => ({
    //     ...item,
    //     name: item.Name_AR,
    //     degreeCode: item.Code,
    //     relations: await axiosFetchRequest(
    //       endpoints.internal.getRelationsByDegree,
    //       {
    //         degree: item.Code
    //       },
    //       "POST"
    //     ).then(data => data.filter(item => item).map(item => ({
    //       ...item,
    //       name: item.nameAr
    //     })))
    //       .catch(err => console.log(`Error Fetching Relations By Degree: ${item.Code}`, err))
    //   })))
    //   return relationDeggreeWithSubs;
    // })])

    const investigationLookups = {
      nationalities: nationalitiesLookup,
      religions: RELIGIONS,
    }

    setData({
      systemScreens,
      investigationLookups,
      // govStakeholders,
      // govEstablishmentStakeholders,
      // mainEntities,
      // relationDegrees,
      cspdTableInfo,
      psdTableInfo,
      ccdTableInfo,
      taskPriorities,
      taskStatuses,
    })
    setLoading(false)
  }

  useEffect(() => {
    handleFetchGlobalData();
  }, []);

  const memoizedValue = useMemo(
    () => ({
      loading,
      ...(data && { ...data }),
    }),
    [
      loading,
      data,
    ]
  );

  return (
    <GlobalDataContext.Provider value={memoizedValue}>{children}</GlobalDataContext.Provider>
  );
}

GlobalDataProvider.propTypes = {
  children: PropTypes.node,
};
