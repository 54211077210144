import { Helmet } from 'react-helmet-async';
import { useScreenGuard } from 'src/hooks/use-permissions';
import { useLocales } from 'src/locales';
// sections
import StakeholdersListView from 'src/sections/system-settings/view/stakeholders/stakeholders-list-view';

// ----------------------------------------------------------------------

export default function StakeholdersListPage() {
  const { t } = useLocales();

  useScreenGuard('SYSTEM_SETTINGS', 'MANAGE_STAKEHOLDERS')

  return (
    <>
      <Helmet>
        <title>{t('screen_stakeholder_list', {
          systemName: t('system_name')
        })}</title>
      </Helmet>

      <StakeholdersListView />
    </>
  );
}
