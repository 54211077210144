import { useMemo } from 'react';
// locales
import { useLocales } from 'src/locales';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/designer/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          {
            title: t('dashboard'),
            path: paths.dashboard.root,
            icon: icon('navbar/dashboard'),
          },
          {
            code: "TASK_LIST",
            title: t('tasklist'),
            path: paths.dashboard.tasks.root,
            icon: icon('navbar/notifications'),
          },
          {
            code: "FINANCIAL_DISCLOSURE",
            title: t('financial_disclosure'),
            path: paths.dashboard.financial_disclosure.root,
            icon: icon('navbar/financial-disclosure'),
          },
          {
            code: "DIRECT_INVESTIGATION",
            title: t('direct_investigation'),
            path: paths.dashboard.investigation.directInvestigation,
            icon: icon('navbar/direct-investigation'),
          },
          {
            code: "STRICT_TRACKING",
            title: t('strict_tracking'),
            path: paths.dashboard.strict_tracking.root,
            icon: icon('navbar/strict-tracking'),
          },
          {
            code: "MANAGEMENT_AUTHORIZATION",
            title: t('management_authorization'),
            path: paths.dashboard.management_auth.root,
            icon: icon('navbar/strict-tracking'),
          },
          {
            title: t('notifications_center'),
            path: paths.dashboard.notification_center,
            icon: icon('navbar/notifications'),
          },
          {
            code: "SYSTEM_SETTINGS",
            title: t('system_settings'),
            path: paths.dashboard.system_settings.root,
            icon: icon('navbar/strict-tracking'),
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
