import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { axiosFetcher } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { apiLogin, fetchRequest, postRequest } from 'src/utils/api';
import { endpoints } from 'src/utils/endpoints';
import { DWT_TOKEN_STORAGE_KEY, TOKEN_STORAGE_KEY } from 'src/config-global';
import { useGetRequest } from '@ahmadmubarak98/use-fetch';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  userPermissions: [],
  directManager: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'SET_USER') {
    return {
      loading: false,
      user: action.payload.user,
      userPermissions: action.payload?.permissions || [],
      directManager: action.payload?.directManager
    };
  }
  if (action.type === 'SET_COUNTERS') {
    return {
      loading: false,
      user: {
        ...state.user,
        counters: action.payload.counters
      },
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------



export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchUserPermissions = async (roleId) => {
    // const response = await fetchRequest(endpoints.internal.getPermissionsByRoleId(roleId)).send()
    // return response;

    const permissions = []
    const data = await fetchRequest(endpoints.internal.getUserPermissions).send();

    for (const permissionsBulk of data) {
      permissions.push(...permissionsBulk?.permissions)
    }

    return permissions;
  }

  const getUserData = useGetRequest({
    url: endpoints.internal.me,
    options: {
      fetcher: axiosFetcher,
      fetchOnMount: false,
    }
  });

  const getDirectManagerReq = useGetRequest({
    url: endpoints.internal.getUserDirectManager,
    options: {
      fetcher: axiosFetcher,
      fetchOnMount: false,
    }
  });

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(TOKEN_STORAGE_KEY);
      const dwtAccessToken = localStorage.getItem(DWT_TOKEN_STORAGE_KEY);

      // if (accessToken && isValidToken(accessToken) && dwtAccessToken) {
      if (accessToken && dwtAccessToken) {
        setSession(accessToken, dwtAccessToken);

        const user = await getUserData.trigger();
        const permissions = await fetchUserPermissions(user.roleId);
        const directManager = await getDirectManagerReq.trigger();

        dispatch({
          type: 'SET_USER',
          payload: {
            user,
            permissions,
            directManager,
          },
        });
      } else {
        dispatch({
          type: 'SET_USER',
          payload: {
            user: null,
            permissions: [],
            directManager: null
          },
        });
      }
    } catch (error) {
      console.error(error);
      // dispatch({
      //   type: 'SET_USER',
      //   payload: {
      //     user: null,
      //     permissions: [],
      //     directManager: null,
      //   },
      // });
    }
  }, []);

  // LOGIN
  const login = useCallback(async (data, onSuccess) => {
    const { username, password } = data;

    try {
      const response = await postRequest(endpoints.internal.basicLogin, {
        Username: username,
        Password: password
      }).send()
      if (response?.data?.isError) {
        console.log("response?.data?.data", response?.data?.data)
        throw new Error(response?.data?.data?.message)
      } else {
        const { data, responseHeaders } = response.data;
        const { accesstoken: token, dwttoken: dwtAccessToken } = responseHeaders;
        await new Promise(resolve => {
          setSession(token, dwtAccessToken);
          setTimeout(() => {
            resolve();
          }, 10);
        });

        const user = await getUserData.trigger();
        const permissions = await fetchUserPermissions(user.roleId)


        const directManager = await getDirectManagerReq.trigger()


        onSuccess?.();


        dispatch({
          type: 'SET_USER',
          payload: {
            user: {
              ...user,
              dwtAccessToken
            },
            permissions,
            directManager
          },
        });


      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  // REGISTER
  const register = useCallback(async (data, onSuccess) => {
    const {
      workspaceName,
      companyName,
      companyField,
      companySize,
      address,
      fromWhereDidYouHearAboutUs,
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
    } = data;

    try {
      // const response = await axios.post(endpoints.auth.register, data);
      onSuccess?.();

      // const { accessToken, user } = response.data;

      // localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);

      // dispatch({
      //   type: 'REGISTER',
      //   payload: {
      //     user,
      //   },
      // });
    } catch (error) {
      console.error(error);
    }
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // Get User Counters
  const getUserCounters = useCallback(async () => {
    const countersEndpoints = [
      // {
      //   key: 'notifications',
      //   endpoint: endpoints.internal.notificationsCount,
      // },
      {
        key: 'tasks',
        endpoint: endpoints.internal.tasksCount,
      }
    ]
    const promises = [...countersEndpoints].map(async (counterEndpoint) => {
      return await fetchRequest(counterEndpoint.endpoint).send().then((data) => ({
        [counterEndpoint.key]: data
      }))
    })

    const counters = await Promise.all(promises)

    dispatch({
      type: 'SET_COUNTERS',
      payload: {
        counters
      },
    });


  }, []);


  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (state.user && !state.user.counters) {
      // getUserCounters()
    }
  }, [state.user])


  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      userPermissions: state.userPermissions,
      directManager: state.directManager,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      accessToken: localStorage.getItem(TOKEN_STORAGE_KEY),
      dwtAccessToken: localStorage.getItem(DWT_TOKEN_STORAGE_KEY),
      //
      getUserCounters,
      login,
      register,
      logout,
    }),
    [getUserCounters, login, logout, register, state.user, state.userPermissions, state.directManager, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
