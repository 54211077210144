import { Helmet } from 'react-helmet-async';
import { useScreenGuard } from 'src/hooks/use-permissions';
import { useLocales } from 'src/locales';
import ScreensListView from 'src/sections/system-settings/view/screens/screens-list-view';
// sections

// ----------------------------------------------------------------------

export default function ScreensListPage() {
  const { t } = useLocales();

  useScreenGuard('SYSTEM_SETTINGS', 'MANAGE_SCREENS')

  return (
    <>
      <Helmet>
        <title>{t('screen_screen_list', {
                    systemName: t('system_name')
                })}</title>
      </Helmet>

      <ScreensListView />
    </>
  );
}
