import { useState, useCallback, useEffect } from 'react';
import shortid from 'shortid';
// @mui
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
// import { varHover } from 'src/components/animate';
import SvgColor from 'src/components/svg-color/svg-color';
//
import NotificationItem from './notification-item';
import { useGetRequest, usePostRequest } from '@ahmadmubarak98/use-fetch';
import { endpoints } from 'src/utils/endpoints';
import { axiosFetcher } from 'src/utils/axios';
import { TableNoData } from 'src/components/table';
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const createNotification = (type, isUnRead, createdAt, title, subTitle, content) => {
  const id = shortid.generate();
  return {
    id,
    type,
    isUnRead,
    createdAt,
    title,
    subTitle,
    content,
  };
};
const INVESTIGATION_NOTIFICATION = {
  type: 'investigation',
  subTitle: 'تحقيق',
  title: 'تحقيق جديد',
  content: 'تم اضافة تحقيق جديد',
};
const CHANGE_PASSWORD_NOTIFICATION = {
  type: 'change_password',
  subTitle: 'كلمة المرور',
  title: 'تغيير كلمة المرور',
  content: 'يجب عليك تغيير كلمة المرور',
};

const NOTIFICATIONS = Array.from({ length: 9 }).map((_, index) => {
  const isUnRead = index % 2 === 0;
  const createdAt = new Date();
  // assign notification randomly
  const notificationsTypes = [INVESTIGATION_NOTIFICATION, CHANGE_PASSWORD_NOTIFICATION];
  const notification = notificationsTypes[Math.floor(Math.random() * notificationsTypes.length)];
  return createNotification(
    notification.type,
    isUnRead,
    createdAt,
    notification.title,
    notification.subTitle,
    notification.content
  );
});

export default function NotificationsPopover() {
  const drawer = useBoolean();

  const { t, currentLang } = useLocales();

  const smUp = useResponsive('up', 'sm');

  const [notifications, setNotifications] = useState([]);

  const totalUnRead = notifications.filter((item) => item.is_read !== 1).length;

  // const getUnreadNotificationsCountReq = useGetRequest({
  //   url: endpoints.internal.getUnreadNotificationsCount,
  //   options: {
  //     fetcher: axiosFetcher,
  //     fetchOnMount: false
  //   }
  // });

  const getUnreadNotificationsReq = useGetRequest({
    url: endpoints.internal.getUnreadNotifications,
    options: {
      fetcher: axiosFetcher,
      fetchOnMount: false
    }
  });

  const setNotificationAsReadReq = usePostRequest({
    options: {
      fetcher: axiosFetcher,
      fetchOnMount: false
    }
  });

  const handleFetchUnreadNotifications = () => {
    getUnreadNotificationsReq.trigger({
      payload: {
        // page: 1,
        // limit: 10,
        // filter: JSON.stringify({
        //   isRead: 0
        // }),
      },
      onSuccess: (res) => {
        setNotifications(res.data)
      }
    })
  }

  const handleSetNotificationAsRead = (notGUID) => {
    setNotificationAsReadReq.trigger({
      url: endpoints.internal.setNotificationAsRead(notGUID),
      onSuccess: () => {
        handleFetchUnreadNotifications()
      }
    })
  }

  useEffect(() => {
    handleFetchUnreadNotifications();
  }, [])

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {t('notifications')}
      </Typography>

      {!!totalUnRead && (
        <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
          {t('unread_num', { num: totalUnRead })}
        </Typography>
      )}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications.length > 0 ? (
          <>
            {notifications.map((item, index) => (
              <NotificationItem
                key={index}
                notification={{
                  ...item,
                  message: currentLang.value === 'ar' ? item['content'] : item['contentEn'],
                  type: item.notification_type,
                }}
                setNotificationAsRead={(guid) => {
                  handleSetNotificationAsRead(guid)
                }}
              />
            ))}
          </>
        ) : (
          <TableNoData
            notFound
            text={t('empty_notifications')}
          />
        )}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        // component={m.button}
        // whileTap="tap"
        // whileHover="hover"
        // variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge
          variant="dot"
          color="error"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          overlap="circular"
          sx={{
            '& .MuiBadge-badge': {
              width: 10,
              height: 10,
              borderRadius: '50%',
              border: '2px solid',
              borderColor: 'background.paper',
              ...(totalUnRead === 0 && ({
                borderWidth: 0,
                background: 'transparent'
              }))
            },
          }}
        >
          <SvgColor src="/assets/icons/designer/bell.svg" color="grey.800" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}
        <Divider />
        {renderList}
        <Divider />
        <Box sx={{ p: 1 }}>
          <Button
            LinkComponent={RouterLink}
            href={paths.dashboard.notification_center}
            fullWidth
            size="large"
          >
            {t('view_all')}
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
