// ----------------------------------------------------------------------

import { PUBLIC_URL } from "src/config-global";

const ROOTS = {
  EXTERNAL: `/external`,
  AUTH: `/auth`,
  DASHBOARD: `/dashboard`,
};

// ----------------------------------------------------------------------

export const paths = {
  notAuthorized: `/403`,
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
    },
  },
  // PUBLIC
  externalCaseProfile: (guid) => `${ROOTS.EXTERNAL}/case-profile/${guid}`,
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    settings: `${ROOTS.DASHBOARD}/settings`,
    notification_center: `${ROOTS.DASHBOARD}/notifications`,
    tasks: {
      root: `${ROOTS.DASHBOARD}/tasks`,
      assignInvestigator: (id) => `${ROOTS.DASHBOARD}/tasks/${id}/assign`,
    },
    investigation: {
      directInvestigation: `${ROOTS.DASHBOARD}/investigation/direct-investigation`,
      directProfile: (nationalId) => `${ROOTS.DASHBOARD}/investigation/direct-profile/${nationalId}`,
      caseProfile: (guid) => `${ROOTS.DASHBOARD}/investigation/case-profile/${guid}`,
    },
    financial_disclosure: {
      root: `${ROOTS.DASHBOARD}/financial-disclosure`,
    },
    strict_tracking: {
      root: `${ROOTS.DASHBOARD}/strict-tracking`,
    },
    management_auth: {
      root: `${ROOTS.DASHBOARD}/management-auth`,
    },
    system_settings: {
      root: `${ROOTS.DASHBOARD}/system-settings`,
      screens_list: `${ROOTS.DASHBOARD}/system-settings/screens-list`,
      roles_list: `${ROOTS.DASHBOARD}/system-settings/roles-list`,
      stakeholders_list: `${ROOTS.DASHBOARD}/system-settings/stakeholders-list`,
      edit_stakeholder_fields: (id) => `${ROOTS.DASHBOARD}/system-settings/stakeholder-fields/${id}`,
    },
  },
};
