import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
// utils
import { fToNow } from 'src/utils/format-time';
// hooks
import { useLocales } from 'src/locales';
// components
import FileThumbnail from 'src/components/file-thumbnail';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function NotificationItem({ notification, setNotificationAsRead, inList }) {
  const { t } = useLocales();
  const { push } = useRouter();

  const handleBtnClick = (e, callback) => {
    e.preventDefault();
    e.stopPropagation();
    if (callback) callback()
    if (setNotificationAsRead) setNotificationAsRead(notification.GUID)
  }

  const renderText = (
    <ListItemText
      disableTypography
      onClick={() => {
        alert('1212')
      }}
      primary={
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body2" color="text.primary" noWrap>
              {notification.subTitle}
            </Typography>
            <Typography variant="caption" color="secondary.main">
              {fToNow(notification.createdAt)}
            </Typography>
          </Box>
        </>
      }
      secondary={
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <Typography variant="subtitle2" fontWeight="fontWeightBold" color="text.primary" noWrap>
              {notification.title}
            </Typography>
            <Box
              sx={{
                borderRadius: '50%',
                bgcolor: 'primary.main',
                position: 'absolute',
              }}
            />
          </Box>
          {reader(notification.message)}
        </>
      }
    />
  );

  const taskAction = (
    <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
      <Button
        onClick={(e) => handleBtnClick(e, () => {
          push(paths.dashboard.tasks.root + `?taskGUID=${notification?.task_id}`)
        })}
        size="small"
        variant="contained"
      >
        {t('show_task')}
      </Button>
    </Stack>
  );



  const investigationAction = (
    <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
      <Button
        onClick={(e) => handleBtnClick(e, () => {
          push(paths.dashboard.investigation.caseProfile(notification?.task_id))
        })}
        size="small"
        variant="contained"
      >
        {t('show_profile')}
      </Button>
    </Stack>
  );

  const friendAction = (
    <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
      <Button size="small" variant="contained">
        Accept
      </Button>
      <Button size="small" variant="outlined">
        Decline
      </Button>
    </Stack>
  );


  const changePasswordAction = (
    <Stack
      alignItems="flex-end"
      sx={{
        mt: 1.5,
      }}
    >
      <Button size="small" variant="contained" color="secondary">
        {t('change_password')}
      </Button>
    </Stack>
  );

  const ContainerEL = inList ? ListItemText : ListItemText;

  return (
    <ContainerEL
      disableRipple
      sx={{
        p: inList ? 0 : 2.5,
        alignItems: 'flex-start',
        ...(!inList && ({
          borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
        }))
      }}
    >
      <Stack sx={{ flexGrow: 1 }}>
        {renderText}
        {/* {notification.type === 'change_password' && changePasswordAction} */}
        {[
          'INVESTIGATION_PROFILE_RECOMMENDATION',
          'INVESTIGATION_PROFILE_MESSAGING',
          'INVESTIGATION_PROFILE_REFRESH',
          'REQUEST_PREV_PROFILES',
          'APPROVE_PREV_PROFILES',
          'REJECT_PREV_PROFILES',
          'REQUEST_CONFIRM_PROFILE',
          'APPROVE_CONFIRM_PROFILE',
          'REJECT_CONFIRM_PROFILE',
        ].includes(notification.type) && (
            investigationAction
          )}
        {[
          'NEW_INVESTIGATION',
          'TASK_RECOMMENDATION',
          'TASK_TRANSFER'
        ].includes(notification.type) && (
            taskAction
          )}
      </Stack>
    </ContainerEL>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
};

// ----------------------------------------------------------------------

function reader(data) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        color: 'text.secondary',
        '& p': { typography: 'body2', m: 0 },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& strong': { typography: 'subtitle2' },
      }}
    />
  );
}
