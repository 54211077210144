// routes
import { t } from 'i18next';
import _ from 'lodash';
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------
export const ROWS_PER_PAGE_OPTIONS = [10, 20, 30]
export const TOKEN_STORAGE_KEY = 'accessToken';
export const DWT_TOKEN_STORAGE_KEY = 'dwtaccesstoken';

export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const HOST_API = process.env.REACT_APP_HOST_API;
export const HOST_API_TOKEN = process.env.REACT_APP_HOST_API_TOKEN;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'http://localhost';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

// PLACEHOLDER IMAGE
export const PLACEHOLDER_IMAGE = `${PUBLIC_URL}/assets/images/placeholder.png`;

// Relations Settings
export const RELATIONS_SORT = {
  // ######## First Degree ######## //
  // Mother & Father
  'parents': 10,
  // Wife + Husband
  74: 20,
  75: 20,
  // Father Wife + Mother Husband
  12: 30,
  13: 30,
  // Children
  3: 40,
  // Wife Children + Husband Children
  11: 50,
  10: 50,
  // Wife Father + Husband Father
  4: 60,
  5: 60,
  // Husband Mother
  6: 70,
  // Daughter Husband + Son Wife
  8: 80,
  9: 80,

  // ######## Second Degree ######## //
  // Grandparents
  14: 90,
  15: 90,
  // Brothers + Sisters
  'siblings': 100,
  'wife-siblings': 110,
  'husband-siblings': 110,
  // Grandchildren
  16: 120,
}

export const RELIGIONS = {
  1: 'rel_islam',
  2: 'rel_christian',
  3: 'rel_jew',
  4: 'rel_none',
  5: 'rel_buddist',
  6: 'rel_unspecified'
}

export const RELATIONS_TO_MERGE = [
  {
    id: 'parents',
    code: 'parents',
    name: 'parents',
    degree: "firstDegree",
    relationsCodes: [1, 2],
  },
  {
    id: 'siblings',
    code: 'siblings',
    name: 'siblings',
    degree: "secondDegree",
    relationsCodes: [17, 18],
  },
  {
    id: 'wife-siblings',
    code: 'wife-siblings',
    name: 'wife-siblings',
    degree: "secondDegree",
    relationsCodes: [20, 22],
  },
  {
    id: 'husband-siblings',
    code: 'husband-siblings',
    name: 'husband-siblings',
    degree: "secondDegree",
    relationsCodes: [19, 21],
  },
  {
    id: 'siblings-children',
    code: 'siblings-children',
    name: 'siblings-children',
    degree: "thirdDegree",
    relationsCodes: [34, 35],
  },
  {
    id: 'wife-siblings-children',
    code: 'wife-siblings-children',
    name: 'wife-siblings-children',
    degree: "thirdDegree",
    relationsCodes: [46, 47],
  },
  {
    id: 'husband-siblings-children',
    code: 'husband-siblings-children',
    name: 'husband-siblings-children',
    degree: "thirdDegree",
    relationsCodes: [44, 45],
  },
]

// Stakeholders Settings
export const GOV_ENTITY_SORT = {
  'PSD': 10,
  'DVLD': 20,
  'DLS': 30,
  'CCD': 40,
  'MIT': 50,
  'SSC': 60,
  'CSB': 70,
  'MOHE': 80,
  'MOLA': 90,
}

export const TABLE_RECORD_MERGE_KEY = {
  DIM_MIT: {
    column: "INDV_NATIONAL_ID",
    label: "view_objectives"
  },
  DIM_CCD: {
    column: "COMID",
    label: "view_objectives"
  },
  CCD_EST: {
    column: "COMID",
    label: "view_objectives"
  },
}

// export const TABLE_RECORD_REAL_ESTATE = {
//   DIM_DLS: {
//     column: "DLS_KEY",
//     btnLabel: "view_properties",
//     tables: [
//       {
//         name: 'DIM_GAM_build_Owners',
//         column: ''
//       }
//     ]
//   }
// }


export const PHONE_NUMBERS_KEYS_STAKEHOLDER = {
  DVLD: ['PRSNL_PHONE_NUM', 'PRSNL_MOBILE_NUM', 'ESTBLSH_MOBILE_NUM'],
  MOHE: ['STUDENT_PHONE'],
  CCD: ['MOBILENO', 'MOBILENO1', 'PHONE']
}

export const INVESTIGATION_SETTINGS = {
  HIDE_EMPTY_STAKEHOLDERS: false,
  HIDE_EMPTY_STAKEHOLDER_TABLES: false,
  HIDE_EMPTY_RECORD_COLUMNS: true,
}

export const SSC_DEPENDENT_TABLES = ['Dim_SSC_Transaction', 'Dim_SSC_Yearly_Salary']

export const TABLES_IDS = {
  CSPD: "6620c3ef1ffdae2d147972aa",
  PSD: "6620ac921ffdae2d147971da",
  CCD: "6620d13c1ffdae2d147972f6"
}

export const ProfilingTypes = {
  DIRECT_INVESTIGATION_PROFILE: "DIRECT_INVESTIGATION_PROFILE",
  CASE_PROFILE: "CASE_PROFILE",
  EXTERNAL_CASE_PROFILE: "EXTERNAL_CASE_PROFILE",
}

export const TASK_STATUS = {
  NEW: "1",
  REJECTED: "2",
  IN_PROGRESS: "3",
  CONFIRMED: "4",
  SAVED: "5",
  SENT: "6",
  CLOSED: '7',
  VERIFIED: '8',
  COMPLETED: '9'
}

export const SYSTEM_ROLES = {
  MANAGER: 1,
  HEAD: 2,
  INVESTIGATOR: 3
}

export const RECOMMENDATION_ACTION_TYPES = {
  REQUEST_CONFIRM_PROFILE: 'REQUEST_CONFIRM_PROFILE',
  APPROVE_CONFIRM_PROFILE: 'APPROVE_CONFIRM_PROFILE',
  REJECT_CONFIRM_PROFILE: 'REJECT_CONFIRM_PROFILE',
  // 
  REQUEST_PREV_PROFILES: 'REQUEST_PREV_PROFILES',
  APPROVE_PREV_PROFILES: 'APPROVE_PREV_PROFILES',
  REJECT_PREV_PROFILES: 'REJECT_PREV_PROFILES',
  // 
  TRANSFER_TASK: 'TRANSFER_TASK',
  RETURN_TO_MANAGER: 'RETURN_TO_MANAGER'

}

export const CASE_PROFILE_ACTION_BUTTON = {
  REQUEST_CONFIRM_PROFILE: 'REQUEST_CONFIRM_PROFILE',
  APPROVE_OR_REJECT_CONFIRM_PROFILE_HEAD: 'APPROVE_OR_REJECT_CONFIRM_PROFILE_HEAD',
  APPROVE_OR_REJECT_CONFIRM_PROFILE_MANAGER: 'APPROVE_OR_REJECT_CONFIRM_PROFILE_MANAGER',
  // 
  REQUEST_PREV_PROFILES: 'REQUEST_PREV_PROFILES',
  APPROVE_OR_REJECT_PREV_PROFILES_HEAD: 'APPROVE_OR_REJECT_PREV_PROFILES_HEAD',
  APPROVE_OR_REJECT_PREV_PROFILES_MANAGER: 'APPROVE_OR_REJECT_PREV_PROFILES_MANAGER',
}

export const PriorityColors = {
  "81C90FB5-6F55-4947-BD69-F6152F30C576": "#dc0000",
  "43932527-DD67-4C9C-927A-67D7E27347FE": "#fd8c00",
  "0AC6EC4A-CAB1-46EF-97F2-63A173BC5786": "#00ac46",
}

export const WatchListRepeatOptions = [
  {
    value: 1,
    label: t('daily'),
  },
  {
    value: 2,
    label: t('weekly'),
  },
  {
    value: 3,
    label: t('monthly'),
  },
  {
    value: 4,
    label: t('yearly'),
  },
];

export const DelegationStatus = {
  active: '7B93E51C-FE90-49B9-86CE-3E90C0B8F314',
  done: '4A378308-0CC8-40CE-8714-10307C1680BA',
};