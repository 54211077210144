import { useCallback, useEffect } from "react";
import { useAuthContext } from "src/auth/hooks";
import { useRouter } from "src/routes/hooks";
import { paths } from "src/routes/paths";


export default function usePermissions() {
    const { userPermissions, roleId } = useAuthContext();
    const { replace } = useRouter();
    // const { systemScreens } = useGlobalDataContext();

    const redirectIfNoPermission = useCallback((screenCode, actionCode) => {
        if (!canViewScreen(screenCode, actionCode)) {
            return replace(paths.notAuthorized)
        }
    }, [userPermissions])

    const canViewScreen = useCallback((screenCode, actionCode) => {
        if (!screenCode) return true;
        if (actionCode) {
            return userPermissions.includes(screenCode + '.' + actionCode)
        }
        return userPermissions.includes(screenCode + '.' + 'VIEW')
    }, [userPermissions]);

    const hasPermission = useCallback((permission, operator = 'AND') => {
        if (Array.isArray(permission)) {
            if (operator === 'AND') {
                return permission.every(permission => userPermissions.includes(permission))
            }
            return permission.some(permission => userPermissions.includes(permission))
        }
        return userPermissions.includes(permission)
    }, [userPermissions]);

    const hasRole = (userRoles = [], role = [], operator = 'AND') => {
        if (Array.isArray(role)) {
            if (operator === 'AND') {
                return role.map(x => parseInt(x)).every(role => userRoles.map(y => parseInt(y)).includes(role))
            }
            return role.map(x => parseInt(x)).some(role => userRoles.map(y => parseInt(y)).includes(role))
        }

        return userRoles.map(x => parseInt(x)).includes(parseInt(role))
    }

    const checkBehalfDelegator = useCallback((actionRole, delegationInfo) => {
        // If user role is same as delegator Role
        if (parseInt(delegationInfo?.delegatorRole) === parseInt(roleId)) {
            // It will always be true (on behalf of delegator)
            return true
        }
        // Else

        // If action role is same as delegator role, then it is on behalf of delegator
        // otherwise, it is not
        if (Array.isArray(actionRole)) {
            return hasRole([parseInt(delegationInfo?.delegatorRole)], actionRole)
        }
        return (parseInt(actionRole) === parseInt(delegationInfo?.delegatorRole))

    }, [roleId]);

    const getDelegationPrefixText = useCallback((actionRole, delegationInfo) => {
        const isOnBehalfOfDelegator = checkBehalfDelegator(actionRole, delegationInfo);
        const prefixText = isOnBehalfOfDelegator ? `كتب بتفويض عن ${delegationInfo?.DelegotorUserNameAr} \n` : '';

        return prefixText;
    }, [roleId, checkBehalfDelegator])


    return {
        redirectIfNoPermission,
        canViewScreen,
        hasPermission,
        hasRole,
        checkBehalfDelegator,
        getDelegationPrefixText,
        userPermissions
    }

}

export function useScreenGuard(screenCode, actionCode) {
    const { redirectIfNoPermission } = usePermissions();

    useEffect(() => {
        redirectIfNoPermission(screenCode, actionCode)
    }, [screenCode, actionCode])
}