import PropTypes from 'prop-types';
import { useMemo, useCallback, useState } from 'react';
import shortid from 'shortid';
// hooks
//
import { GlobalDialogContext } from './global-dialog-context';

// ----------------------------------------------------------------------

export function GlobalDialogProvider({ children }) {
  // ** State
  const [dialogs, setDialogs] = useState([]);

  // ** Functions
  const onOpen = useCallback((options) => {
    const randomId = shortid.generate();
    setDialogs((prevDialogs) => [
      ...prevDialogs,
      {
        ...options,
        backdrop: options.backdrop ?? true,
        dismissable: options.dismissable ?? true,
        showCloseBtn: options.showCloseBtn ?? true,
        title: options.title ?? '',
        content: options.content ?? '',
        dialogProps: options.dialogProps ?? {},
        id: randomId,
        onClose: () => onClose(randomId),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = useCallback(() => {
    // Close last dialog
    setDialogs((prevDialogs) => {
      const newDialogs = [...prevDialogs];
      newDialogs.pop();
      return newDialogs;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedValue = useMemo(
    () => ({
      dialogs,
      onOpen,
      onClose,
    }),
    [dialogs, onOpen, onClose]
  );

  return (
    <GlobalDialogContext.Provider value={memoizedValue}>{children}</GlobalDialogContext.Provider>
  );
}

GlobalDialogProvider.propTypes = {
  children: PropTypes.node,
};
