import { Helmet } from 'react-helmet-async';
import { useScreenGuard } from 'src/hooks/use-permissions';
import { useLocales } from 'src/locales';
// sections
import SystemSettingsView from 'src/sections/system-settings/view/all-settings-view';

// ----------------------------------------------------------------------

export default function SystemSettingsPage() {
  const { t } = useLocales();

  useScreenGuard('SYSTEM_SETTINGS', 'VIEW')

  return (
    <>
      <Helmet>
        <title>{t('screen_system_settings', {
                    systemName: t('system_name')
                })}</title>
      </Helmet>

      <SystemSettingsView />
    </>
  );
}
