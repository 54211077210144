import { createAlova } from "alova";
import GlobalFetch from "alova/GlobalFetch";
import ReactHook from "alova/react";
import { DWT_TOKEN_STORAGE_KEY, TOKEN_STORAGE_KEY } from "src/config-global";

const handleResponded = async (response, config) => {
  const responseJSON = await response.json();


  let responseHeaders = {};
  response.headers.forEach((header, key) => {
    responseHeaders[key] = header
  })


  return {
    data: responseJSON?.data || responseJSON,
    responseHeaders,
    isError: !!!response.ok,
    ...(!!!response.ok && ({
      errorMessage: responseJSON.message
    }))
  };
}

const alovaInstance = createAlova({
  statesHook: ReactHook,
  requestAdapter: GlobalFetch(),
  beforeRequest(method) {
    let accessToken = localStorage.getItem(TOKEN_STORAGE_KEY);
    let dwtAccessToken = localStorage.getItem(DWT_TOKEN_STORAGE_KEY);
    if (accessToken) {
      method.config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    if (dwtAccessToken) {
      method.config.headers['dwtaccesstoken'] = `${dwtAccessToken}`
    }
    method.config.headers['Content-Type'] = method.config.headers['Content-Type'] ? method.config.headers['Content-Type'] : 'application/json'
  },
  async responsed(response, config) {
    return await handleResponded(response, config)
  },
});

export const alovaUploadInstance = createAlova({
  statesHook: ReactHook,
  requestAdapter: GlobalFetch(),
  async responsed(response, config) {
    return await handleResponded(response, config)
  },
});

export default alovaInstance;
