import { useState } from "react";
import { Box, Button, List, ListItem, ListItemButton, Menu, Typography } from "@mui/material";

export default function SuggestionComplete({ label, options, handleChooseOption }) {
    const [suggestionAnchorEl, setSuggestionAnchorEl] = useState(null)
    const sugestionOpen = Boolean(suggestionAnchorEl);

    const handleOpenSuggestion = (e) => {
        setSuggestionAnchorEl(e.currentTarget)
    }

    const handleCloseSuggestion = () => {
        setSuggestionAnchorEl(null)
    }

    return (
        <>
            <Button onClick={handleOpenSuggestion}>{label}</Button>
            <Menu
                open={sugestionOpen}
                anchorEl={suggestionAnchorEl}
                onClose={handleCloseSuggestion}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
            >
                <Box
                    sx={{
                        py: 1,
                        minWidth: 200,
                    }}
                >
                    <Box
                        sx={{
                            mt: 1,
                            p: 1,
                        }}
                    >
                        <List>
                            {options?.map((option, index) => (
                                <ListItem key={index} disablePadding>
                                    <ListItemButton onClick={() => handleChooseOption(option.value)}>
                                        <Typography fontSize={11}>{option.label}</Typography>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Menu>
        </>
    )
}