import { t } from "i18next";
import * as Yup from "yup";
import isEmailValidator from "validator/lib/isEmail"
import { calculateDateRules } from "./calculate-date-rules";
import { checkRule } from "./check-rule";
import { validatePhoneNumber } from "./validate-phone-number";

const getFieldTypeValue = (field) => {
  switch (field.type) {
    case "input":
      return field.inputType === "number" ? "number" : "string";

    case "select":
      return field.multiple ? "array" : "string";

    case "multi-checkbox":
      return "array";
    // return field.multiple ? "array" : "string";

    case "repeater":
      return "array";

    case "object-editor":
      return "object";

    case "checkbox":
      return "boolean";

    case "date":
      return "date";

    default:
      return "string";
  }
};

const generateValidations = (field) => {

  let schema = Yup[field.typeValue || getFieldTypeValue(field)]();


  const translateMessage = (rule) => {
    return Array.isArray(rule.message)
      ? t(rule.message[0], {
        ...({
          ...rule.message[1],
        } || {}),
      })
      : t(rule.message);
  }

  if ((getFieldTypeValue(field) === "date" || field.typeValue === "date")) {
    schema = schema.nullable().typeError(t("please_enter_valid_date"))
  }

  if (!field.validations) return null;

  for (const rule of field.validations) {
    // Check if the field has visibility rules,
    // if so, apply the validations only when the field is visible
    // otherwise, apply the validations without checking visibility rules
    if (field.visibilityRules?.length) {
      let newSchema = generateValidations({
        ...field,
        visibilityRules: [],
      });
      schema = schema.when(field.visibilityRules.map((visibilityRule) => visibilityRule.field),
        (values, schema) => {
          let isFieldHidden = false;
          field.visibilityRules.forEach((visibilityRule, index) => {
            const operand = visibilityRule.operand || "AND";
            if (operand === "AND") {
              if (!checkRule(visibilityRule, values[index])) {
                isFieldHidden = true;
              }
            } else if (operand === "OR") {
              if (checkRule(visibilityRule, values[index])) {
                isFieldHidden = false;
              }
            }

          })
          return !isFieldHidden ? newSchema : schema.notRequired();
          // let isFieldVisible = true;
          // field.visibilityRules.forEach((visibilityRule, index) => {
          //   if (!checkRule(visibilityRule, values[index])) {
          //     isFieldVisible = false;
          //   }
          // })
          // return isFieldVisible ? newSchema : schema.notRequired();
        }
      )
      // field.visibilityRules.forEach((visibilityRule) => {
      //   schema = schema.when(visibilityRule.field, {
      //     is: (value) => checkRule(visibilityRule, value),
      //     then: () => {
      //       // Apply validations to the field, but remove visibility rules,
      //       // in order to avoid checking them again
      //       let newSchema = generateValidations({
      //         ...field,
      //         visibilityRules: [],
      //       });
      //       return newSchema;
      //     },
      //     otherwise: (schema) => schema.notRequired(),
      //   });
      // });
    } else {
      switch (rule.type) {
        case "required":
          schema = schema.required(translateMessage(rule));
          break;
        case "email":
          schema = schema.email(translateMessage(rule))
            .test(
              "is-valid-email",
              translateMessage(rule),
              (value) => {
                if (value?.length === 0) {
                  return true
                }
                return value ? isEmailValidator(value) : new Yup.ValidationError(translateMessage(rule))
              }
            )
          break;
        case "phone":
          // schema = schema
          //   .when(field.fieldVariable, {
          //     is: (value) => {
          //       console.log("v", value?.replace(" ", ""));
          //       console.log("length", value?.replace(" ", "")?.length);
          //       return value?.replace(" ", "")?.length > 9
          //     },
          //     // then must equal enteredCaptcha
          //     then: (schema) =>
          //       schema.matches(
          //         /^(?=(?:\D*\d\D*){10,16}$)[ -]*\+?[ -]*(?:\((?:[ -]*\d)+[ -]*\))?[- \d]*/,
          //         {
          //           message: translateMessage(rule),
          //           excludeEmptyString: false,
          //         }
          //       )
          //   })

          // schema = schema.test('len', translateMessage(rule), value => {
          //   const valueFiltered = value?.replace(/[^\d/+]/g, "")
          //   if (valueFiltered?.length <= 4) {
          //     return true;
          //   }
          //   return valueFiltered?.length >= 9 && valueFiltered?.length <= 16
          // })

          schema = schema.test(
            "is-valid-phone",
            translateMessage(rule),
            (value) => {
              if (value?.length === 0) {
                return true;
              }
              return validatePhoneNumber(value.replace(" ", ""))
            }
          )
          break;
        case "min":
          if (rule?.message[1]?.test) {
            rule.message[1].test()
          }
          // if type is date
          if (field.type === "date") {
            schema = schema.min(
              calculateDateRules(rule.value, "min"),
              translateMessage(rule)
            );
          } else {
            schema = schema.min(rule.value, translateMessage(rule));
          }
          break;
        case "max":
          if (field.type === "date") {
            schema = schema.max(
              calculateDateRules(rule.value, "max"),
              translateMessage(rule)
            );
          } else {
            schema = schema.max(rule.value, translateMessage(rule));
          }
          break;
        case "minLength":
          schema = schema.matches(new RegExp(`.{${rule.value},}`), {
            excludeEmptyString: true,
            message: translateMessage(rule)
          })
          break;
        case "maxLength":
          schema = schema.max(rule.value, translateMessage(rule));
          break;
        case "when":
          schema = schema.when(rule.field, {
            is: (value) => {
              if (rule.operator) {
                return checkRule(rule, value)
              } else {
                return Array.isArray(rule.value)
                  ? rule.value.includes(value)
                  : rule.value;
              }
            },
            then: (schema) => {
              if (["repeater", "multi-checkbox"].includes(field.type)) {
                return schema.min(1, translateMessage(rule));
              }
              return schema.required(translateMessage(rule))
            },
          });
          break;
        case "whenNot":
          schema = schema.when(rule.field, {
            is: (value) =>
              // in array or equal string
              Array.isArray(rule.value)
                ? !rule.value.includes(value)
                : rule.value !== value,
            then: (schema) => schema.required(translateMessage(rule)),
          });
          break;
        default:
          break;
      }
    }
  }

  return schema;
};

export const getForm = (formFields) => {
  let defaultValues = {};

  let validationsFields = {};

  for (const field of formFields) {
    if (field.type !== "form-section") {
      switch (field.type) {
        case "input":
          if (field.inputType === "number") {
            defaultValues[field.fieldVariable] = field.value || 0;
          } else {
            defaultValues[field.fieldVariable] = field.value || "";
          }
          break;
        case "phonefield":
          defaultValues[field.fieldVariable] = field.value || "";
          break;
        case "date":
          defaultValues[field.fieldVariable] = field.value || undefined;
          break;
        case "select":
          if (field.multiple) {
            defaultValues[field.fieldVariable] = field.value || [];
          } else {
            defaultValues[field.fieldVariable] = field.value || "";
          }
          break;
        case "multi-checkbox":
          defaultValues[field.fieldVariable] = field.value || [];
          break;
        case "repeater":
          defaultValues[field.fieldVariable] = field.value || [];
          break;
        case "checkbox":
          defaultValues[field.fieldVariable] = field.value || false;
          break;
        case "object-editor":
          defaultValues[field.fieldVariable] = field.value || {};
          break;
        case "radio-group":
          defaultValues[field.fieldVariable] = field.value || null;
          break;
        default:
          defaultValues[field.fieldVariable] = field.value || "";
          break;
      }
    }

    if (!field.validations) continue;

    const schema = generateValidations(field);

    validationsFields[field.fieldVariable] = schema;
  }

  const isFieldRequired = (field) => {
    return field.validations?.some(
      (validation) => validation.type === "required" || validation.type === "min"
    );
  };

  return {
    validationSchema: Yup.object({
      ...validationsFields,
      captcha: Yup.string().when("generatedCaptcha", {
        is: (value) => !!value,
        // then must equal enteredCaptcha
        then: (schema) =>
          Yup.string().required("required").oneOf([Yup.ref("generatedCaptcha")], t("invalid_captcha")),
      }),
    }),
    defaultValues: defaultValues,
    fields: formFields.map((field) => ({
      ...field,
      required: isFieldRequired(field),
    })),
  };
};
