// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';

// ----------------------------------------------------------------------

import { useEffect } from 'react';
// @mui
import { GlobalStyles } from '@mui/material';
// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// locales
import { LocalizationProvider } from 'src/locales';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// contexts
import { AuthProvider, AuthConsumer } from 'src/auth/context';
import { GlobalDataProvider } from './contexts/global-data/global-data-provider';
import { AccessibilityProvider } from './components/accessibility';
import { CursorProvider } from './components/cursor/context/cursor-provider';
import { OnboardingTour, OnboardingTourProvider } from './components/onboarding-tour';
import { GlobalDialog, GlobalDialogProvider } from './components/global-dialog';
import { GlobalDrawer, GlobalDrawerProvider } from './components/global-drawer';
import { GlobalPrompt, GlobalPromptProvider } from './components/global-prompt';
import Cursor from './components/cursor/cursor';
import { Toaster } from 'react-hot-toast';

// ----------------------------------------------------------------------

export default function App() {
  /* eslint-disable no-useless-escape */
  const charAt = `
  ██████╗░░█████╗░████████╗██████╗░░█████╗░███╗░░██╗
  ██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗██╔══██╗████╗░██║
  ██████╔╝███████║░░░██║░░░██████╔╝██║░░██║██╔██╗██║
  ██╔═══╝░██╔══██║░░░██║░░░██╔══██╗██║░░██║██║╚████║
  ██║░░░░░██║░░██║░░░██║░░░██║░░██║╚█████╔╝██║░╚███║
  ╚═╝░░░░░╚═╝░░╚═╝░░░╚═╝░░░╚═╝░░╚═╝░╚════╝░╚═╝░░╚══╝`;

  useEffect(() => {
    console.info(`%c${charAt}`, 'background: #91E0EF; color: #02045F');
    console.info('%c 🚀 Made by Ahmad Mubarak', 'background: #02045F; color: #ffffff');
  }, [charAt]);

  useScrollToTop();

  return (
    <GlobalDataProvider>
      <AuthProvider>
        <LocalizationProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'jiacc', // 'default' | 'patron' | 'jiacc' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <AccessibilityProvider
              defaultSettings={{
                rootFontSize: 100,
                colorBlind: false,
              }}
            >
              <CursorProvider>
                <ThemeProvider>
                  <GlobalStyles
                    styles={{
                      '.stickyTableCell': {
                        position: 'sticky !important',
                        boxShadow: '5px 2px 5px grey !important',
                      },
                      '.react-joyride__tooltip, .react-joyride__tooltip *': {
                        fontFamily: 'Cairo, sans-serif !important',
                      },
                    }}
                  />
                  <GlobalDialogProvider>
                    <GlobalPromptProvider>
                      <GlobalDrawerProvider>
                        <MotionLazy>
                          <OnboardingTourProvider>
                            <OnboardingTour />
                            <SettingsDrawer />
                            <GlobalDrawer />
                            <GlobalDialog />
                            <GlobalPrompt />
                            <ProgressBar />
                            <Toaster
                              position='bottom-center'
                              toastOptions={{
                                duration: 4000,
                                style: {
                                  minWidth: '300px',
                                  minHeight: '60px',
                                  fontWeight: 'bold'
                                }
                              }}
                            />
                            <Cursor />
                            <AuthConsumer>
                              <Router />
                            </AuthConsumer>
                          </OnboardingTourProvider>
                        </MotionLazy>
                      </GlobalDrawerProvider>
                    </GlobalPromptProvider>
                  </GlobalDialogProvider>
                </ThemeProvider>
              </CursorProvider>
            </AccessibilityProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </AuthProvider>
    </GlobalDataProvider>
  );
}
