import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, IconButton, InputAdornment, FormHelperText } from '@mui/material';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from '../iconify/iconify';

// ----------------------------------------------------------------------

export default function RHFTextField({ name, helperText, type, inputType, ...other }) {
  const { control } = useFormContext();
  const isSecure = useBoolean(type === 'password');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            {...field}
            {...other}
            fullWidth
            // eslint-disable-next-line no-nested-ternary
            type={type}
            value={type === 'number' && field.value === 0 ? '' : field.value}
            onChange={(event) => {
              if (type === 'number') {
                field.onChange(Number(event.target.value));
              } else {
                if (event.target.value === " ") return

                if (inputType === "numeric-text") {
                  if (!isNaN(event.target.value) && !event.target.value.includes(" ") && !event.target.value.includes(".")) {
                    field.onChange(event.target.value);
                  }
                  return
                }
                field.onChange(event.target.value);
              }
            }}
            error={!!error}
            {...(inputType === "numeric-text") && {
              min: 0,
              step: 1
            }}
            sx={{
              ...(other.multiline && {
                overflowX: 'hidden'
              })
            }}
          // InputProps={{
          //   ...other.InputProps,
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <IconButton onClick={isSecure.onToggle} edge="end">
          //         <Iconify icon={isSecure.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
          //       </IconButton>
          //     </InputAdornment>
          //   ),
          // }}
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  inputType: PropTypes.string,
};
