import { Box, Button, Card, CardHeader, Container } from "@mui/material";
import { useSettingsContext } from "src/components/settings";
import { useBackRoute } from "src/hooks/use-back-route";
import { paths } from "src/routes/paths";
import { useLocales } from "src/locales";
import { LoadingScreen } from "src/components/loading-screen";
import { useTable } from "src/components/table";
import Table from "src/components/table/table";
import { StyledActionButton } from "src/components/custom-styled-components";
import { useGlobalDialogContext } from "src/components/global-dialog";
// import ScreenActions from "./modals/screen-actions";
import { useGlobalPromptContext } from "src/components/global-prompt";
import RolePermissions from "./modals/role-permissions";
import { useGetRequest } from "@ahmadmubarak98/use-fetch";
import { axiosFetcher } from "src/utils/axios";
import { endpoints } from "src/utils/endpoints";




export default function RolesListView() {
    const { t } = useLocales();
    const settings = useSettingsContext();
    const globalDialog = useGlobalDialogContext();
    const globalPrompt = useGlobalPromptContext();
    const table = useTable({
        defaultDense: false,
        defaultOrder: 'asc',
        defaultOrderBy: 'id',
        defaultCurrentPage: 0,
        defaultRowsPerPage: 10,
        defaultSelected: [],
    });

    const getRolesListReq = useGetRequest({
        url: endpoints.internal.getAllRoles,
        options: {
            initialData: [],
            fetcher: axiosFetcher,
        }
    });

    const columns = [
        { id: 'name', label: t('name'), minWidth: 200 },
        {
            type: 'actions',
            label: t('action'),
            align: 'center',
            minWidth: 160,
        },
    ];


    const onViewPermissionsClick = (roleId, roleName) => {
        globalDialog.onOpen({
            dialogProps: {
                maxWidth: 'md',
            },
            title: t('edit_role_permissions', {
                roleName
            }),
            content: (
                <RolePermissions roleId={roleId} />
            ),
        });
    }


    useBackRoute(paths.dashboard.system_settings.root);


    // if (loading) return <LoadingScreen />

    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <CardHeader title={t('roles_permissions_management')} />

            <Card>
                <Table
                    {...table}
                    columns={columns}
                    rows={getRolesListReq.data?.data}
                    loading={getRolesListReq.isLoading}
                    renderActions={(row) => (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                gap: 1,
                            }}
                        >
                            <StyledActionButton
                                onClick={() => onViewPermissionsClick(row?.pmk_ID, row.name)}
                                variant="outlined"
                                color="secondary"
                                size="small"
                            >
                                {t('role_permissions')}
                            </StyledActionButton>
                        </Box>
                    )}
                />

            </Card>
        </Container>
    )
}