import { Box, Card } from "@mui/material";
import { useRequest } from "alova";
import { StyledActionButton } from "src/components/custom-styled-components";
import { useGlobalDialogContext } from "src/components/global-dialog";
import { LoadingScreen } from "src/components/loading-screen";
import { useTable } from "src/components/table";
import Table from "src/components/table/table";
import { useLocales } from "src/locales";
import { useRouter } from "src/routes/hooks";
import { paths } from "src/routes/paths";
import { fetchRequest } from "src/utils/api";
import { endpoints } from "src/utils/endpoints";


export default function StakeholderTables({ stakeholderId, entityType }) {
    const router = useRouter();
    const globalDialog = useGlobalDialogContext();
    const { t } = useLocales();
    const table = useTable({
        defaultDense: false,
        defaultOrder: 'asc',
        defaultOrderBy: 'id',
        defaultCurrentPage: 0,
        defaultRowsPerPage: 10,
        defaultSelected: [],
    });
    const {
        loading,
        send,
        data,
    } = useRequest(
        fetchRequest(endpoints.internal.investigationEntityTables(stakeholderId))
    );

    // ** Vars
    const columns = [
        { id: 'tableName', label: t('table_name'), minWidth: 200 },
        { id: 'description', label: t('description'), minWidth: 200 },
        {
            type: 'actions',
            label: t('action'),
            align: 'center',
            minWidth: 160,
        },
    ];

    if (loading) return <LoadingScreen />
    return (
        <Box py={2}>
            <Card>
                <Table
                    {...table}
                    columns={columns}
                    rows={data}
                    renderActions={(row) => (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                gap: 1,
                            }}
                        >
                            <StyledActionButton
                                onClick={() => {
                                    globalDialog.onClose()
                                    router.push(paths.dashboard.system_settings.edit_stakeholder_fields(row._id) + (entityType === "establishment" ? `?entityType=establishment` : ""))
                                }}
                                variant="outlined"
                                color="secondary"
                                size="small"
                            >
                                {t('edit_fields')}
                            </StyledActionButton>
                        </Box>
                    )}
                />
            </Card>
        </Box>
    )
}