import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
//
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
import { useGlobalDataContext } from 'src/contexts/global-data/global-data-context';
import { CircularProgress } from '@mui/material';
import { useNavData } from './config-navigation';
import { usePathname } from 'src/routes/hooks';
import { matchPath } from 'react-router';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const settings = useSettingsContext();
  const navigation = useNavData();
  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;


  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp && !settings.hideNav && renderHorizontal}
        {!lgUp && !settings.hideNav && renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {/* {lgUp ? renderNavMini : renderNavVertical} */}
          {renderNavVertical}
          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  // navigation?.[0]?.items?.find(item => matchPath({
  //   path: item.path,
  //   caseSensitive: false,
  //   end: true
  // }, pathname))

  // if (navigation?.[0]?.items?.find(item => item.path ===)) {
  //   return (
  //     <Box
  //       sx={{
  //         minWidth: 300,
  //         maxWidth: 800,
  //         mx: 'auto',
  //         py: 2
  //       }}
  //     >
  //       <Alert severity='error'>
  //         <b>{t("attention")}</b>
  //         <Box>
  //           {t("not_authorized")}
  //         </Box>
  //       </Alert>
  //     </Box>
  //   )
  // }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
