import { Box, Button, Card, CardHeader, Container } from "@mui/material";
import { useSettingsContext } from "src/components/settings";
import { useBackRoute } from "src/hooks/use-back-route";
import { paths } from "src/routes/paths";
import { useLocales } from "src/locales";
import { LoadingScreen } from "src/components/loading-screen";
import { useTable } from "src/components/table";
import Table from "src/components/table/table";
import { StyledActionButton } from "src/components/custom-styled-components";
import { useGlobalDialogContext } from "src/components/global-dialog";
import ScreenActions from "./modals/screen-actions";
import AddEditScreen from "./modals/add-edit-screen";
import { useGlobalPromptContext } from "src/components/global-prompt";
import { useFetch, useGetRequest, usePostRequest } from "@ahmadmubarak98/use-fetch";
import { axiosFetcher } from "src/utils/axios";
import { endpoints } from "src/utils/endpoints";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-hot-toast";


const TABLE_DATA = [
    {
        code: "TASK_LIST",
        nameEn: "Task List",
        nameAr: "قائمة المهام"
    },
    {
        code: "PROFILE",
        nameEn: "Profile",
        nameAr: "الملف الشخصي"
    }
]


export default function ScreensListView() {
    const { t } = useLocales();
    const settings = useSettingsContext();
    const globalDialog = useGlobalDialogContext();
    const globalPrompt = useGlobalPromptContext();
    const table = useTable({
        defaultDense: false,
        defaultOrder: 'asc',
        defaultOrderBy: 'id',
        defaultCurrentPage: 0,
        defaultRowsPerPage: 10,
        defaultSelected: [],
    });

    const getScreenListReq = useGetRequest({
        url: endpoints.internal.getAllScreens,
        options: {
            initialData: [],
            fetcher: axiosFetcher,
        }
    });

    const deleteScreenReq = useFetch({
        options: {
            method: 'DELETE',
            initialLoading: false,
            fetchOnMount: false,
            fetcher: axiosFetcher,
        }
    });

    const columns = [
        { id: 'code', label: t('code'), minWidth: 200 },
        { id: 'nameEn', label: t('name_en'), minWidth: 200 },
        { id: 'nameAr', label: t('name_ar'), minWidth: 200 },
        {
            type: 'actions',
            label: t('action'),
            align: 'center',
            minWidth: 160,
        },
    ];


    const onViewScreenActionsClick = (screen) => {
        globalDialog.onOpen({
            dialogProps: {
                maxWidth: 'md',
            },
            title: t('screen_actions'),
            content: (
                <ScreenActions screenData={screen} refetchScreens={getScreenListReq.trigger} />
            ),
        });
    }

    const onAddNewScreenClick = () => {
        globalDialog.onOpen({
            dialogProps: {
                maxWidth: 'md',
            },
            title: t('add_screen'),
            content: (
                <AddEditScreen refetchScreens={getScreenListReq.trigger} />
            ),
        });
    }

    const onEditScreenClick = (screen) => {
        globalDialog.onOpen({
            dialogProps: {
                maxWidth: 'md',
            },
            title: t('edit_screen'),
            content: (
                <AddEditScreen screenData={screen} refetchScreens={getScreenListReq.trigger} />
            ),
        });
    }

    const onDeleteScreenClick = (screenId) => {
        globalPrompt.onOpen({
            promptProps: {
                hideActions: true,
                hideCancel: true,
                dismissable: false,
                icon: "success",
                confirmText: t("yes"),
                onConfirm: () => {
                    deleteScreenReq.trigger({
                        url: endpoints.internal.deleteScreenById(screenId),
                        onSuccess: () => {
                            toast(t('delete_success'), {
                                icon: '✅',
                            })
                            getScreenListReq.trigger();
                        }
                    })
                },
                onCancel: () => { },
            },
            title: t("warning"),
            content: `${t("are_you_sure")}`,
        });
    }


    useBackRoute(paths.dashboard.system_settings.root);


    // if (loading) return <LoadingScreen />

    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <CardHeader title={t('screen_management')} action={
                <>
                    <Button
                        onClick={onAddNewScreenClick}
                        variant="contained"
                        color="secondary"
                    >
                        {t("add_new_screen")}
                    </Button>
                </>
            } />

            <Card>
                <Table
                    {...table}
                    columns={columns}
                    rows={getScreenListReq.data}
                    loading={getScreenListReq.isLoading}
                    renderActions={(row) => (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                gap: 1,
                            }}
                        >
                            <StyledActionButton
                                onClick={() => onViewScreenActionsClick(row)}
                                variant="outlined"
                                color="secondary"
                                size="small"
                            >
                                {t('screen_actions')}
                            </StyledActionButton>
                            <StyledActionButton
                                variant="outlined"
                                color="secondary"
                                size="small"
                                onClick={() => onEditScreenClick(row)}
                            >
                                {t('edit')}
                            </StyledActionButton>
                            <LoadingButton
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={() => onDeleteScreenClick(row._id)}
                                loading={deleteScreenReq.isLoading}
                            >
                                {t('delete')}
                            </LoadingButton>
                        </Box>
                    )}
                />

            </Card>
        </Container>
    )
}