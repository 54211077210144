import { Box, Button, IconButton } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { BlockPicker } from "react-color";

export default function ColorPicker({ value, onChange }) {
    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(prev => !prev)
    const handleClose = () => setOpen(false)

    return (
        <div
            style={{
                position: 'relative'
            }}
        >
            <Box
                onClick={handleClick}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    border: t => `1px solid ${t.palette.primary.main}`,
                    borderRadius: '5px',
                    p: 0.5,
                    height: 20,
                    width: 20,
                    backgroundColor: value,
                    cursor: 'pointer'
                }}>
            </Box>
            {open && (
                <div
                    style={{
                        top: 40,
                        position: 'absolute',
                        zIndex: 2
                    }}
                >
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0
                    }}
                        onClick={handleClose}
                    />
                    <BlockPicker value={value} onChange={(color) => onChange(color.hex)} triangle='hide' />
                </div>
            )}
        </div>
    )
}