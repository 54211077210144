import { Box, Button, Card, CardHeader } from "@mui/material";
import { StyledActionButton } from "src/components/custom-styled-components";
import { useGlobalDialogContext } from "src/components/global-dialog";
import { useTable } from "src/components/table";
import Table from "src/components/table/table";
import { useLocales } from "src/locales";
import { useRouter } from "src/routes/hooks";
import AddEditAction from "./add-edit-action";
import { useGlobalPromptContext } from "src/components/global-prompt";
import { useCallback, useEffect, useState } from "react";
import { usePostRequest } from "@ahmadmubarak98/use-fetch";
import { endpoints } from "src/utils/endpoints";
import { axiosFetcher } from "src/utils/axios";
import { LoadingScreen } from "src/components/loading-screen";



export default function ScreenActions({ screenData, refetchScreens }) {
    const router = useRouter();
    const globalDialog = useGlobalDialogContext();
    const globalPrompt = useGlobalPromptContext();
    const { t } = useLocales();
    const table = useTable({
        defaultDense: false,
        defaultOrder: 'asc',
        defaultOrderBy: 'id',
        defaultCurrentPage: 0,
        defaultRowsPerPage: 10,
        defaultSelected: [],
    });
    // State
    const [loading, setLoading] = useState(true);
    const [actions, setActions] = useState([]);

    const addEditScreenActionReq = usePostRequest({
        options: {
            initialLoading: false,
            fetchOnMount: false,
            fetcher: axiosFetcher,
        }
    });


    useEffect(() => {
        setLoading(false)
        if (screenData?.actions) {
            console.log("reloaded!!!", screenData.actions);
            setActions(screenData.actions)
        }
    }, [screenData])

    // ** Vars
    const columns = [
        { id: 'code', label: t('code'), minWidth: 200 },
        { id: 'nameEn', label: t('name_en'), minWidth: 200 },
        { id: 'nameAr', label: t('name_ar'), minWidth: 200 },
        {
            type: 'actions',
            label: t('action'),
            align: 'center',
            minWidth: 160,
        },
    ];

    const onAddNewActionClick = () => {
        globalDialog.onOpen({
            dialogProps: {
                maxWidth: 'md',
            },
            title: t('add_action'),
            content: (
                <AddEditAction onSubmit={(newAction) => {
                    setActions(prev => [...prev, newAction])
                }} />
            ),
        });
    }

    const onEditActionClick = (action, index) => {
        globalDialog.onOpen({
            dialogProps: {
                maxWidth: 'md',
            },
            title: t('edit_action'),
            content: (
                <AddEditAction action={action} onSubmit={(newAction) => {
                    setActions(prev => {
                        let newActions = prev.slice();
                        newActions[index] = newAction;
                        return newActions;
                    })
                }} />
            ),
        });
    }

    const onDeleteActionClick = (index) => {
        globalPrompt.onOpen({
            promptProps: {
                hideActions: true,
                hideCancel: true,
                dismissable: false,
                icon: "success",
                confirmText: t("yes"),
                onConfirm: () => {
                    setActions(prev => {
                        const cloned = prev.slice();
                        cloned.splice(index, 1)
                        return cloned;
                    })
                },
                onCancel: () => { },
            },
            title: t("warning"),
            content: `${t("are_you_sure")}`,
        });
    }

    const onSaveScreenActions = useCallback(() => {
        addEditScreenActionReq.trigger({
            url: endpoints.internal.editScreenById(screenData._id),
            payload: {
                actions
            },
            onSuccess: () => {
                globalDialog.onClose();
                if (refetchScreens) refetchScreens();
            }

        })
    }, [actions])

    if (loading) return <LoadingScreen />
    return (
        <Box py={2}>
            <Card>
                <Button
                    onClick={onAddNewActionClick}
                    variant="contained"
                    color="secondary"
                >
                    {t("add_new_action")}
                </Button>
                <Table
                    {...table}
                    columns={columns}
                    rows={actions}
                    renderActions={(row, index) => (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                gap: 1,
                            }}
                        >
                            <StyledActionButton
                                variant="outlined"
                                color="secondary"
                                size="small"
                                onClick={() => onEditActionClick(row, index)}
                            >
                                {t('edit')}
                            </StyledActionButton>
                            <StyledActionButton
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={() => onDeleteActionClick(index)}
                            >
                                {t('delete')}
                            </StyledActionButton>
                        </Box>
                    )}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button
                        onClick={onSaveScreenActions}
                        variant="contained"
                        color="secondary"
                    >
                        {t("save")}
                    </Button>
                </Box>
            </Card>
        </Box>
    )
}