import { Helmet } from 'react-helmet-async';
import { useScreenGuard } from 'src/hooks/use-permissions';
import { useLocales } from 'src/locales';
// sections
import EditStakeholderFieldsView from 'src/sections/system-settings/view/stakeholders/edit-stakeholder-fields-view';

// ----------------------------------------------------------------------

export default function EditStakeholderFieldsPage() {
  const { t } = useLocales();

  useScreenGuard('SYSTEM_SETTINGS', 'MANAGE_STAKEHOLDERS')
  return (
    <>
      <Helmet>
        <title>{t('screen_stakeholder_fields', {
                    systemName: t('system_name')
                })}</title>
      </Helmet>

      <EditStakeholderFieldsView />
    </>
  );
}
