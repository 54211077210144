import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import TablePagination from '@mui/material/TablePagination';
import { MenuItem, Pagination, Select, Stack, Typography } from '@mui/material';
import { t } from 'i18next';

// ----------------------------------------------------------------------

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25],
  rowsPerPage = 1,
  sx,
  ...other
}) {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 1
      }}
    >


      {(other?.total > 0) && <Stack direction='row' alignItems='center' spacing={1}>
        <Typography>{t('number_of_entries')}: </Typography>
        <Select
          size='small'
          value={rowsPerPage}
          onChange={(val) => other?.onRowsPerPageChange(val)}
          sx={{
            padding: 0
          }}
        >
          {rowsPerPageOptions.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </Stack>}
      {(other?.page && other?.count > 0) && <Pagination
        variant="outlined"
        shape="rounded"
        color="secondary"
        count={parseInt(other?.count)}
        page={parseInt(other?.page)}
        onChange={(e, page) => other?.onPageChange(page)}
      />}


      {/* <TablePagination
        component="div"
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={rowsPerPage}
        labelDisplayedRows={() => "Asd"}
        {...other}
        sx={{
          borderTopColor: 'transparent',
        }}
      /> */}

      {/* {onChangeDense && (
        <FormControlLabel
          label="Dense"
          control={<Switch checked={dense} onChange={onChangeDense} />}
          sx={{
            pl: 2,
            py: 1.5,
            top: 0,
            position: {
              sm: 'absolute',
            },
          }}
        />
      )} */}
    </Box>
  );
}

TablePaginationCustom.propTypes = {
  dense: PropTypes.bool,
  onChangeDense: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  rowsPerPage: PropTypes.number,
  sx: PropTypes.object,
};
