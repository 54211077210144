import { Box, Card, } from "@mui/material";
import { getForm } from "src/components/dynamic-form";
import DynamicForm from "src/components/dynamic-form/dynamic-form";
import { useGlobalDialogContext } from "src/components/global-dialog";
import { useLocales } from "src/locales";
import { useRouter } from "src/routes/hooks";



export default function AddEditAction({ action, onSubmit }) {
    const router = useRouter();
    const { t } = useLocales();
    const globalDialog = useGlobalDialogContext();
    const form = getForm([
        {
            type: 'input',
            inputType: 'text',
            fieldVariable: 'code',
            label: 'code',
            tip: 'code',
            placeholder: 'code',
            isAffectedByOtherFields: false,
            affectingFields: [],
            optionsSourceType: null,
            optionsSourceApi: '',
            optionsSourceApiToken: '',
            optionsSourceApiLabelKey: '',
            optionsSourceApiValueKey: '',
            options: [],
            fields: [],
            canDuplicate: false,
            canReorder: false,
            multiline: null,
            rows: '',
            value: '',
            validations: [{ type: 'required', message: 'required' }],
            visibilityRules: [],
            gridOptions: [
                { breakpoint: 'xs', size: 12 },
                { breakpoint: 'md', size: 4 },
            ],
        },
        {
            type: 'input',
            inputType: 'text',
            fieldVariable: 'nameEn',
            label: 'name_en',
            tip: 'name_en',
            placeholder: 'name_en',
            isAffectedByOtherFields: false,
            affectingFields: [],
            optionsSourceType: null,
            optionsSourceApi: '',
            optionsSourceApiToken: '',
            optionsSourceApiLabelKey: '',
            optionsSourceApiValueKey: '',
            options: [],
            fields: [],
            canDuplicate: false,
            canReorder: false,
            multiline: null,
            rows: '',
            value: '',
            validations: [{ type: 'required', message: 'required' }],
            visibilityRules: [],
            gridOptions: [
                { breakpoint: 'xs', size: 12 },
                { breakpoint: 'md', size: 4 },
            ],
        },
        {
            type: 'input',
            inputType: 'text',
            fieldVariable: 'nameAr',
            label: 'name_ar',
            tip: 'name_ar',
            placeholder: 'name_ar',
            isAffectedByOtherFields: false,
            affectingFields: [],
            optionsSourceType: null,
            optionsSourceApi: '',
            optionsSourceApiToken: '',
            optionsSourceApiLabelKey: '',
            optionsSourceApiValueKey: '',
            options: [],
            fields: [],
            canDuplicate: false,
            canReorder: false,
            multiline: null,
            rows: '',
            value: '',
            validations: [{ type: 'required', message: 'required' }],
            visibilityRules: [],
            gridOptions: [
                { breakpoint: 'xs', size: 12 },
                { breakpoint: 'md', size: 4 },
            ],
        },
    ]);
    const defaultValues = {
        ...form.defaultValues,
        ...action
    };

    const handleOnSubmit = async (data) => {
        if (onSubmit) onSubmit(data)
        globalDialog.onClose();
    };

    // if (loading) return <LoadingScreen />
    return (
        <Box py={2}>
            <Card>
                <DynamicForm
                    {...form}
                    defaultValues={defaultValues}
                    validationMode="onChange"
                    onSubmit={handleOnSubmit}
                    submitButtonProps={{
                        label: t('save'),
                        alignment: 'right',
                        width: '300px',
                        // loading: loadingSearch
                    }}
                />

            </Card>
        </Box>
    )
}