import { useCallback } from 'react';
import { debounce } from 'lodash';
// @mui
import { Box, IconButton, Stack } from '@mui/material';
import Iconify from 'src/components/iconify/iconify';
// hooks
import { useAccessibilityContext } from '../context';
import SvgColor from 'src/components/svg-color/svg-color';

export default function AccessibilityToolbar() {
  const {
    onReset,
    onDecreaseRootFontSize,
    onIncreaseRootFontSize,
    onIncreaseLetterSpacing,
    colorBlind,
    onToggleColorBlind,
    cursorMode,
    onChangeCursorMode,
  } = useAccessibilityContext();

  const bigCursor = cursorMode === 'big';
  const readingCursor = cursorMode === 'reading';

  const onToggleBigCursor = useCallback(() => {
    if (cursorMode === 'big') {
      onChangeCursorMode('auto');
    } else {
      onChangeCursorMode('big');
    }
  }, [cursorMode, onChangeCursorMode]);

  const onToggleReadingCursor = useCallback(() => {
    if (cursorMode === 'reading') {
      onChangeCursorMode('auto');
    } else {
      onChangeCursorMode('reading');
    }
  }, [cursorMode, onChangeCursorMode]);

  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        color: 'common.white',
      }}
    >
      <Stack direction="row" spacing={1}>
        {/* font size increase/decrease */}
        <Stack direction="row" spacing={0}>
          <IconButton
            size="small"
            onClick={debounce(onDecreaseRootFontSize, 100)}
            sx={{
              color: (t) => t.palette.common.white,
            }}
          >
            <SvgColor src="/assets/icons/accessibility/minus.svg" color="white" width={21} />
          </IconButton>
          <IconButton
            size="small"
            onClick={debounce(onIncreaseRootFontSize, 100)}
            sx={{
              color: (t) => t.palette.common.white,
            }}
          >
            <SvgColor src="/assets/icons/accessibility/plus.svg" color="white" width={21} />
          </IconButton>
        </Stack>

        {/* Letter Spacing */}
        <Stack direction="row" spacing={0}>
          <IconButton
            size="small"
            onClick={debounce(onIncreaseLetterSpacing, 100)}
            sx={{
              color: (t) => t.palette.common.white,
            }}
          >
            <SvgColor src="/assets/icons/accessibility/letter-spacing.svg" color="white" width={21} />
            <Iconify icon="material-symbols:format-letter-spacing-2-rounded" width={15} />
          </IconButton>
        </Stack>

        {/* color blind */}
        <IconButton
          size="small"
          onClick={debounce(onToggleColorBlind, 100)}
          sx={{
            color: (t) => t.palette.common.white,
          }}
        >
          <SvgColor src={colorBlind ? "/assets/icons/accessibility/eye-off.svg" : "/assets/icons/accessibility/eye.svg"} color="white" width={21} />
        </IconButton>

        {/* big cursor */}
        <IconButton
          size="small"
          onClick={debounce(onToggleBigCursor, 100)}
          sx={{
            color: (t) => t.palette.common.white,
          }}
        >
          <SvgColor src={bigCursor ? "/assets/icons/accessibility/cursor-fill.svg" : "/assets/icons/accessibility/cursor.svg"} color="white" width={21} />
        </IconButton>

        {/* reading cursor */}
        <IconButton
          size="small"
          onClick={debounce(onToggleReadingCursor, 100)}
          sx={{
            color: (t) => t.palette.common.white,
          }}
        >
          <SvgColor src={readingCursor ? "/assets/icons/accessibility/mouse-off.svg" : "/assets/icons/accessibility/mouse.svg"} color="white" width={21} />
        </IconButton>

        {/* reset */}
        <IconButton
          size="small"
          onClick={debounce(onReset, 100)}
          sx={{
            color: (t) => t.palette.common.white,
          }}
        >
          <SvgColor src={"/assets/icons/accessibility/reload.svg"} color="white" width={21} />
        </IconButton>
      </Stack>
    </Box>
  );
}
