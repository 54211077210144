import { SOCKET_URL } from "src/config-global";
import { io } from 'socket.io-client'

const { createContext, useRef, useEffect, useContext, useState, useMemo } = require("react");

const NotificationsSocket = createContext();

const ourSocket = io(SOCKET_URL + '/notifications', {
  // was /backend
    path: '/socket.io',
    autoConnect: true
});

export const NotificationsSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);


    useEffect(() => {
        ourSocket.connect();

        setSocket(ourSocket)


        return () => {
            socket?.disconnect();
        }
    }, []);


    return (
        <NotificationsSocket.Provider value={socket}>
            {children}
        </NotificationsSocket.Provider>
    )
}

export const useInvProfileChatSocket = () => {
    return useContext(NotificationsSocket)
}
