// @mui
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Box,
} from '@mui/material';
// hooks
import { useGlobalDialogContext } from './context';
// components
import SvgColor from '../svg-color/svg-color';

// ----------------------------------------------------------------------

export default function GlobalDialog() {
  const { dialogs } = useGlobalDialogContext();

  const handleClose = (globalDialog) => {
    if (!globalDialog?.dismissable) return

    if (globalDialog.onClose) globalDialog.onClose()
  }

  return (
    <>
      {dialogs.map((dialog) => {
        const globalDialog = dialog;

        return (
          <Dialog
            key={globalDialog.id}
            open
            fullWidth
            maxWidth={globalDialog?.dialogProps?.maxWidth || 'xs'}
            onClose={() => handleClose(globalDialog)}
            sx={{
              ...globalDialog?.dialogProps?.sx,
              '& .MuiDialog-paper': {
                ...globalDialog?.dialogProps?.sx?.paper,
              },
            }}
          >
            {!globalDialog?.title && (
              <Box
                sx={{
                  // cursor: 'move',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: '100%',
                  height: 20,
                  zIndex: 0,
                }}
              />
            )}
            {globalDialog.showCloseBtn && <IconButton
              onClick={globalDialog.onClose}
              sx={{
                position: 'absolute',
                top: (theme) => theme.spacing(1),
                right: (theme) => theme.spacing(1),
              }}
            >
              <SvgColor src="/assets/icons/designer/close.svg" color="text.secondary" width={24} />
            </IconButton>}
            {globalDialog.title && (
              <DialogTitle
                sx={{
                  pb: 2,
                  textAlign: 'center',
                  // cursor: 'move',
                  userSelect: 'none',
                }}
              >
                {globalDialog.title}
              </DialogTitle>
            )}

            {globalDialog.content && (
              <DialogContent
                sx={{
                  ...globalDialog?.dialogProps?.sx?.content,
                  typography: 'body2',
                  ...(!globalDialog.title && {
                    pt: 2,
                  }),
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {globalDialog.content}
              </DialogContent>
            )}

            {globalDialog.action && <DialogActions>{globalDialog?.action}</DialogActions>}
          </Dialog>
        );
      })}
    </>
  );
}
