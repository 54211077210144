import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// auth
import { useAuthContext } from 'src/auth/hooks';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

const METHODS = [
  {
    id: 'jwt',
    label: 'Jwt',
    path: paths.auth.jwt.login,
    icon: '/assets/icons/auth/ic_jwt.svg',
  },
];

export default function AuthClassicLayout({ children, image, title }) {
  const { method } = useAuthContext();

  const theme = useTheme();

  const upMd = useResponsive('up', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        p: { xs: 3, md: 5 },
        backgroundColor: (t) => alpha(t.palette.background.default, 0.72),
        border: (t) => `solid 1px ${t.palette.divider}`,
        borderRadius: 1.5,
        boxShadow: (t) => t.customShadows.z20,
      }}
    >
      {children}
    </Stack>
  );

  return (
    <>
      <Box
        component="main"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          // ...bgGradient({
          //   color: alpha(
          //     theme.palette.background.default,
          //     theme.palette.mode === 'light' ? 0.88 : 0.94
          //   ),
          //   imgUrl: '/assets/background/overlay_2.jpg',
          // }),
          background: 'background.default',
        }}
      >
        {/* {renderLogo} */}
        <Box
          sx={{
            width: {
              xs: '90%',
              lg: 600,
            },
          }}
        >
          {renderContent}
        </Box>
      </Box>
    </>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
