import merge from 'lodash/merge';
import { enUS as enUSAdapter, arSA as arSAAdapter } from 'date-fns/locale';
// core
import { enUS as enUSCore, arSA as arSACore } from '@mui/material/locale';
// date-pickers
import { enUS as enUSDate } from '@mui/x-date-pickers/locales';
// data-grid
import { enUS as enUSDataGrid, arSD as arSDDataGrid } from '@mui/x-data-grid';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Arabic',
    value: 'ar',
    direction: 'rtl',
    systemValue: merge(arSDDataGrid, arSACore),
    adapterLocale: arSAAdapter,
    icon: 'flagpack:jo',
  },
  {
    label: 'English',
    value: 'en',
    direction: 'ltr',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
];

export const defaultLang = allLangs[0]; // English
