import { useCallback, useMemo } from 'react';
import { useSearchParams as _useSearchParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useSearchParams() {
  const [searchParams, setSearchParams] = _useSearchParams();

  const changeSearchParam = useCallback((param, value) => {
    let prevParams = {};
    for (let paramEntry of searchParams.entries()) {
      prevParams = {
        ...prevParams,
        [paramEntry[0]]: paramEntry[1]
      }
    }
    setSearchParams({
      ...prevParams,
      [param]: value
    })
  }, [searchParams]);

  return useMemo(() => ({
    searchParams,
    changeSearchParam,
    setSearchParams
  }), [searchParams]);
}
