import { useState, useCallback } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
//
import SvgColor from '../../svg-color';
import { useSettingsContext } from '../context';

// ----------------------------------------------------------------------

export default function FullScreenOption() {
  const [fullscreen, setFullscreen] = useState(false);
  const settings = useSettingsContext();

  const onToggleFullScreen = useCallback(() => {
    // settings.onUpdate('themeLayout', settings.themeLayout === 'vertical' ? 'mini' : 'vertical')

    if (!document.fullscreenElement) {
      // settings.onUpdate('themeLayout', 'mini')
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      // settings.onUpdate('themeLayout', 'vertical')
      document.exitFullscreen();
      setFullscreen(false);
    }
  }, [settings]);

  return (
    <Box sx={{ 
      // p: 2.5 
      }}>
      <ButtonBase
        onClick={onToggleFullScreen}
        sx={{
          // width: 1,
          // height: 48,
          borderRadius: 1,
          color: 'text.disabled',
          typography: 'subtitle2',
          // border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
          // ...(fullscreen && {
          //   color: 'text.primary',
          // }),
          '& .svg-color': {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.grey[500]} 0%, ${theme.palette.grey[600]} 100%)`,
            ...(fullscreen && {
              background: (theme) =>
                `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
            }),
          },
        }}
      >
        <SvgColor
          src={`/assets/icons/setting/${fullscreen ? 'ic_exit_full_screen' : 'ic_full_screen'}.svg`}
          sx={{ width: 16, height: 16, }}
        />

        {/* {fullscreen ? 'Exit Fullscreen' : 'Fullscreen'} */}
      </ButtonBase>
    </Box>
  );
}
