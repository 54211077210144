import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Box, Button } from '@mui/material';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useLocales } from 'src/locales';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { AccessibilityToolbar } from 'src/components/accessibility';
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import { RouterLink } from 'src/routes/components';
//
import { HEADER, NAV } from '../config-layout';
import { AccountPopover, NotificationsPopover, DateTimeOverview, SettingsButton } from '../_common';
import FullScreenOption from 'src/components/settings/drawer/fullscreen-option';
import { NotificationsSocketProvider } from 'src/contexts/notifications-socket';
// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const { t } = useLocales();

  const isNavVertical = settings.themeLayout === 'vertical';

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');
  const smUp = useResponsive('up', 'sm');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      {smUp && settings.backRoute && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1, lg: 6 }}
          sx={{
            mx: 2,
          }}
        >
          <Button
            LinkComponent={RouterLink}
            href={settings.backRoute}
            variant="outlined"
            sx={{
              p: 1,
              backgroundColor: 'common.white',
            }}
            startIcon={
              <SvgColor
                sx={{
                  color: 'secondary.main',
                }}
                src="/assets/icons/designer/back.svg"
              />
            }
          >
            {t('back')}
          </Button>
        </Stack>
      )}

      <AccountPopover />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1, lg: 6 }}
      >

        <Box
          flexGrow={1}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={{ xs: 0.5, sm: 1, lg: 3 }}
          >
            {/* <LanguagePopover /> */}

            <NotificationsSocketProvider>
              <NotificationsPopover />
            </NotificationsSocketProvider>


            {/* <ContactsPopover /> */}

            <FullScreenOption />

            {/* <SettingsButton /> */}

            {/* <Searchbar /> */}
          </Stack>
        </Box>

        {smUp && <DateTimeOverview />}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        paddingBottom: 12,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...((isNavVertical || isNavMini) && {
            borderBottom: `1px solid ${theme.palette.divider}`,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `1px solid ${theme.palette.primary.main}`,
          }),
          // ...(isNavMini && {
          //   width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          // }),
        }),
      }}
    >
      <AccessibilityToolbar />

      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
